// import './MainPage.css';
import React, { useState } from "react";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import roadmapphoto from "../../../assets/Roadmap_alpha-min.png";
import Popup from "reactjs-popup";

const Slider4 = ({ anchor }) => {
	const [open, setOpen] = useState(false);
	const [text, setText] = useState(false);
	const closeModal = () => setOpen(false);

	return (
		<>
			<div
				className="container-custom flex flex-col align-items-center"
				id={`${anchor}`}
			>
				<div className="xl:my-auto  text-center">
					{/* <h2 className="text-center uppercase mb-4">RoadMap</h2> */}
					<img src={roadmapphoto} />
				</div>
			</div>
		</>
	);
};
export default Slider4;
