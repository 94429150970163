import React from "react";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import Footer from "../../../Components/Footer/Footer";

const Slider6 = () => {
  return (
    <>
      <ToastContainer />
      <Footer />
    </>
  );
};

export default Slider6;
