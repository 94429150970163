import React, { useContext, useEffect, useState } from "react";
import { Footer } from "../../Components";
import Header from "../../Components/Header/Header";
import AvatarProfile from "../Profile/AvatarProfile";

import ButtonsContent from "../Profile/ButtonsContent";
import HeroProfile from "../Profile/HeroProfile";
import soon from "../../assets/soon.png";
import { UserContext } from "../../Providers/UserProvider";
import { getManualUserTokens, getUserTokens } from "../../Api/Api";
import { auth } from "../../Api";
import { timeConverter } from "../../Utils";

const TransactionsLex = () => {
	const [user] = useContext(UserContext);
	const [loading, setLoading] = useState(true);
	const [transactions, setTransactions] = useState([]);
	const [manualTransactions, setManualTransactions] = useState([]);

	useEffect(() => {
		auth
			.auth()
			.currentUser.getIdToken(/* forceRefresh */ true)
			.then(async function (idToken) {
				getUserTokens(idToken).then(response => {
					if (response != -1) {
						setTransactions(response.transactions);
						getManualUserTokens(idToken).then(response => {
							if (response != -1) {
								setManualTransactions(response.transactions);
								setLoading(false);
							} else {
								setLoading(false);
							}
						});
					} else {
						setLoading(false);
					}
				});
			});
	}, []);

	return (
		<>
			<h3 className="text-white my-4 text-center">TOKENS FROM EXCHANGE</h3>
			{transactions.length > 0 ? (
				<table className="min-w-full divide-y divide-gray-300">
					<thead className="">
						<tr>
							<th
								scope="col"
								className="hidden md:block whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-300 sm:pl-6"
							>
								ID
							</th>
							<th
								scope="col"
								className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-300"
							>
								Date
							</th>
							<th
								scope="col"
								className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-300"
							>
								Tokens
							</th>
							<th
								scope="col"
								className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-300"
							>
								Cost
							</th>
							<th
								scope="col"
								className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-300"
							>
								Method
							</th>
							<th
								scope="col"
								className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-300"
							>
								Provider
							</th>
						</tr>
					</thead>
					<tbody className="divide-y divide-gray-200 text-gray-300">
						{transactions.map(transaction => (
							<tr key={transaction.id}>
								<td className="hidden md:block whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-300 sm:pl-6">
									{transaction.id}
								</td>
								<td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-300">
									{timeConverter(transaction.createdAt)}
								</td>
								<td className="whitespace-nowrap px-2 py-2 text-sm text-gray-300">
									{parseFloat(transaction.toAmount).toFixed(2) +
										" " +
										transaction.toAsset.symbol}
								</td>
								<td className="whitespace-nowrap px-2 py-2 text-sm text-gray-300">
									{parseFloat(transaction.fromAmount).toFixed(2) +
										" " +
										transaction.fromSymbol}
								</td>
								<td className="whitespace-nowrap px-2 py-2 text-sm text-gray-300">
									{transaction.typeOperationName}
								</td>
								<td className="whitespace-nowrap px-2 py-2 text-sm text-gray-300">
									CryptoCoinPro
								</td>
							</tr>
						))}
					</tbody>
				</table>
			) : (
				<div className="text-gray-300 text-center my-4">
					No transactions found in this account from exchange.
				</div>
			)}

			<h3 className="text-white my-2 text-center">
				Tokens from OTHER sources.
			</h3>
			{manualTransactions.length > 0 ? (
				<table className="min-w-full divide-y divide-gray-300">
					<thead className="">
						<tr>
							<th
								scope="col"
								className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-300"
							>
								Date
							</th>
							<th
								scope="col"
								className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-300"
							>
								Amount
							</th>
							<th
								scope="col"
								className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-300"
							>
								Source
							</th>
						</tr>
					</thead>
					<tbody className="divide-y divide-gray-200 text-gray-300">
						{manualTransactions.map(transaction => (
							<tr key={transaction._id}>
								<td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-300">
									{transaction.createdAt}
								</td>
								<td className="whitespace-nowrap px-2 py-2 text-sm text-gray-300">
									{parseFloat(transaction.amount).toFixed(2)}
								</td>
								<td className="whitespace-nowrap px-2 py-2 text-sm text-gray-300">
									{transaction.source}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			) : (
				<div className="text-gray-300 text-center my-4">
					{" "}
					No transactions found in this account from OTHER sources.
				</div>
			)}
		</>
	);
};
export default TransactionsLex;
