import React from 'react';
import { Footer } from '../../Components';
import Header from '../../Components/Header/Header';
import AvatarProfile from '../Profile/AvatarProfile';
import soon from '../../assets/soon.png';

import ButtonsContent from '../Profile/ButtonsContent';
import HeroProfile from '../Profile/HeroProfile';
import Overview from '../Profile/Overview';
const items = [
  { number: '#123', name: 'Coming soon' },
  { number: '#133', name: 'Coming soon' },
  { number: '#142', name: 'Coming soon' },
  { number: '#143', name: 'Coming soon' },
  { number: '#165', name: 'Coming soon' },
];
const ProfileImperium = () => {
  return (
    <>
      <Header />
      <div className="pt-12 lg:pt-24"></div>
      <HeroProfile />
      <div className="bg-back-profile md:min-h-screen">
        <div className="max-w-1883px mx-auto px-6">
          <div className=" pt-10 pd:mt-0 flex flex-col md:grid  grid-rows-2 md:grid-cols-8 md:gap-10">
            <div className=" -mt-32 md:mt-0 md:col-span-3 lg:col-span-2 ">
              <AvatarProfile />
            </div>
            <div className=" col-span-5 lg:col-span-6 pb-10 pt-10 md:pt-0">
              <ButtonsContent />
              <div className=" mt-5 rounded-xl mr-5">
                {/* <h2 className="text-center py-5">Coming soon</h2> */}
                <img src={soon} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default ProfileImperium;
