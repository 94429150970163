import { React, useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import { Footer, ScrollTopButton, SEO } from "../../Components";
import soon from "../../assets/to_be_announced.png";
import axios from "axios";
import { Link } from "react-router-dom";
const Blog2 = () => {
	const [posts, setPosts] = useState();
	useEffect(() => {
		const fetchPost = async () => {
			try {
				fetch("https://cms.imperium-lex.com/wp-json/wp/v2/posts")
					.then(response => response.json())
					.then(posts2 => {
						console.log(posts2);
						setPosts(posts2);
					});
				const res = await axios.get(
					`https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@imperiumindex`
				);
				const response = res.data;
				console.log(response.items);
				return null;
			} catch (err) {
				console.log(err);
			}
		};
		fetchPost();
	}, []);

	return (
		<>
			<SEO
				title="IMPERIUM - THE CRYPTO INDEX"
				description="IMPERIUM is both a collection of indices that are tracking the top cryptocurrencies worldwide and a tool for investing in them according to each asset’s market cap."
			/>
			<Header />
			<div className="pt-24"></div>
			<div className="container-custom">
				<h1 className="text-center text-4xl lg:text-7xl pb-5"></h1>
				<div className="">
					{posts
						? posts.map((item, index) => (
								<div className="grid grid-cols-1 lg:grid-cols-8 mb-10">
									<div className="lg:col-span-6 text-white pr-20 order-2 lg:order-1">
										<div className="mt-2">
											<p>{new Date(item.date).toLocaleDateString()}</p>
										</div>
										{/* <h5>{item.pubDate.slice(0, 10)}</h5> */}
										<Link to={"/blog/" + item.slug}>
											<div
												className="text-white"
												dangerouslySetInnerHTML={{
													__html: "<h3>" + item.title.rendered + "</h3>"
												}}
											></div>
										</Link>

										<div
											className="text-white text-base hidden lg:block"
											style={({ fontWeight: 300 }, { fontSize: 10 })}
											dangerouslySetInnerHTML={{
												__html: item.excerpt.rendered
											}}
										/>
										<div
											className="text-white text-base lg:hidden"
											style={({ fontWeight: 300 }, { fontSize: 10 })}
											dangerouslySetInnerHTML={{
												__html: item.excerpt.rendered.slice(0, 100) + " [...]"
											}}
										/>
										<div className="mt-2 mb-5">
											<Link to={"/blog/" + item.slug}>
												<p>Read more</p>
											</Link>
										</div>
										{/* <p>{{ __html: item.content.rendered }}</p> */}
									</div>
									<div className="lg:col-span-2 order-1 lg:order-2">
										<Link to={"/blog/" + item.slug}>
											<img src={item.featured_image_url} />
										</Link>
									</div>
								</div>
						  ))
						: ""}
				</div>
			</div>
			{/* <div data-gallery-include="w3GXH"></div> */}
			<ScrollTopButton />
			<Footer />
		</>
	);
};

export default Blog2;
