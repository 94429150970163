import React from "react";
import { Footer, ScrollTopButton } from "../../Components";
import Header from "../../Components/Header/Header";

import image_1 from "../../assets/im/1.jpg";
import image_2 from "../../assets/im/2.jpg";
import image_3 from "../../assets/im/3.jpg";
import image_4 from "../../assets/im/4.jpg";
import image_5 from "../../assets/im/5.jpg";

const IM20alt = () => {
	return (
		<>
			<Header />
			<div className="m:pt-2 pt-24">
				<div className="container-custom pb-96">
					<h1 className="text-center text-4xl lg:text-7xl pb-5">
						IM20alt Coming soon...
					</h1>
					<p className="text-center text-lg lg:text-xl pb-5 text-white">
						Detailed analytics data on the index in the form of graphs, charts,
						and tables
					</p>
					<div>
						<h3 className="text-center py-5">
							IM20alt historical behaviour chart
						</h3>
						<img src={image_1} />
						<h3 className="text-center py-5">
							IM20alt historical coin dominance
						</h3>
						<img src={image_2} />
						<br />
						<img src={image_3} />
						<h3 className="text-center py-5">IM20alt historical investment</h3>

						<img src={image_4} />
						<br />
						<img src={image_5} />
					</div>
				</div>
			</div>
			<ScrollTopButton />
			<Footer />
		</>
	);
};

export default IM20alt;
