// import './MainPage.css';
import React, { useEffect, useState } from "react";
import "./Slider1.css";
import bg from "../../../assets/bg.mp4";
import logonou from "../../../assets/logonou.png";
import { Cta } from "../../../Components";

const Slider1 = (props) => {
  const [play, setPlay] = useState(false);

  useEffect(() => {
    document.getElementById("videoclip").play();
  });

  return (
    <div className="h-full div_text_banner" id={`${props.anchor}`}>
      <video
        autoPlay
        loop
        muted
        playsInline
        className="videoTag"
        id="videoclip"
      >
        <source src={bg} type="video/mp4" />
      </video>
      <div className="h-full pb-4 flex flex-col align-items-center">
        <div className="my-auto text_banner">
          <div className="p-8 mx-auto mt-24">
            <div className="text-center uppercase">
              <img src={logonou} className="logo_nou" />
            </div>
            <h2 className="uppercase text-center">The Crypto Index</h2>
            <h3 className="text-white text-center mt-4">
              Simplified & Diversified Investing
            </h3>
          </div>
          <div className="flex flex-col items-center md:flex-row justify-center mt-4">
            <div className="flex flex-row items-center mx-4 my-2 item-slider">
              <svg
                id="comment"
                xmlns="http://www.w3.org/2000/svg"
                width="32.57"
                height="32.57"
                viewBox="0 0 32.57 32.57"
              >
                <path
                  id="Path_11"
                  data-name="Path 11"
                  d="M16.285,32.57a16.288,16.288,0,0,0,15.64-20.825,1.357,1.357,0,1,0-2.6.762,13.553,13.553,0,1,1-5.469-7.494,1.357,1.357,0,0,0,1.517-2.25A16.287,16.287,0,1,0,16.285,32.57Zm0,0"
                  transform="translate(0 0)"
                  fill="#00d7fb"
                />
                <path
                  id="Path_12"
                  data-name="Path 12"
                  d="M132.757,32.4,118.788,46.368,114.319,41.9a1.357,1.357,0,0,0-1.919,1.919l5.428,5.428a1.357,1.357,0,0,0,1.919,0L134.676,34.32a1.357,1.357,0,1,0-1.919-1.919Zm0,0"
                  transform="translate(-102.503 -29.289)"
                  fill="#00d7fb"
                />
              </svg>{" "}
              <p className="text-white pl-2">
                Always keep your funds in the top crypto currencies
              </p>
            </div>
            <div className="flex flex-row items-center mx-4 my-2 item-slider">
              <svg
                id="comment"
                xmlns="http://www.w3.org/2000/svg"
                width="32.57"
                height="32.57"
                viewBox="0 0 32.57 32.57"
              >
                <path
                  id="Path_11"
                  data-name="Path 11"
                  d="M16.285,32.57a16.288,16.288,0,0,0,15.64-20.825,1.357,1.357,0,1,0-2.6.762,13.553,13.553,0,1,1-5.469-7.494,1.357,1.357,0,0,0,1.517-2.25A16.287,16.287,0,1,0,16.285,32.57Zm0,0"
                  transform="translate(0 0)"
                  fill="#00d7fb"
                />
                <path
                  id="Path_12"
                  data-name="Path 12"
                  d="M132.757,32.4,118.788,46.368,114.319,41.9a1.357,1.357,0,0,0-1.919,1.919l5.428,5.428a1.357,1.357,0,0,0,1.919,0L134.676,34.32a1.357,1.357,0,1,0-1.919-1.919Zm0,0"
                  transform="translate(-102.503 -29.289)"
                  fill="#00d7fb"
                />
              </svg>{" "}
              <p className="text-white pl-2">
                Assets backed by real investment
              </p>
            </div>
            <div className="flex flex-row items-center mx-4 my-2 item-slider">
              <svg
                id="comment"
                xmlns="http://www.w3.org/2000/svg"
                width="32.57"
                height="32.57"
                viewBox="0 0 32.57 32.57"
              >
                <path
                  id="Path_11"
                  data-name="Path 11"
                  d="M16.285,32.57a16.288,16.288,0,0,0,15.64-20.825,1.357,1.357,0,1,0-2.6.762,13.553,13.553,0,1,1-5.469-7.494,1.357,1.357,0,0,0,1.517-2.25A16.287,16.287,0,1,0,16.285,32.57Zm0,0"
                  transform="translate(0 0)"
                  fill="#00d7fb"
                />
                <path
                  id="Path_12"
                  data-name="Path 12"
                  d="M132.757,32.4,118.788,46.368,114.319,41.9a1.357,1.357,0,0,0-1.919,1.919l5.428,5.428a1.357,1.357,0,0,0,1.919,0L134.676,34.32a1.357,1.357,0,1,0-1.919-1.919Zm0,0"
                  transform="translate(-102.503 -29.289)"
                  fill="#00d7fb"
                />
              </svg>{" "}
              <p className="text-white pl-2">
                Crypto-Authorized in the EU & fully regulated
              </p>
            </div>
          </div>
          {/* <div className="flex justify-center mt-6 mb-6">
            <Cta
              text="RO"
              icon="telegram"
              href="https://t.me/ImperiumRo"
              target="_BLANK"
              className="flex items-center flex-row"
            />
            <Cta
              text="EN"
              icon="telegram"
              href="https://t.me/ImperiumEN"
              target="_BLANK"
              className="ml-2 flex items-center flex-row"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default Slider1;
