import React from "react";
import { Footer, ScrollTopButton } from "../../Components";
import Header from "../../Components/Header/Header";
import defi from "../../assets/indexes/Coming-soon.jpg";
const DeFiIndex = () => {
	return (
		<>
			<Header />
			<div className="m:pt-2 pt-24">
				<div className="container-custom pb-96">
					<h1 className="text-center text-4xl lg:text-7xl pb-5">
						Imperium DeFi Index Coming soon...
					</h1>
					<p className="text-center text-lg lg:text-xl pb-5 text-white">
						Composition Table
					</p>
					<div className="flex justify-center mt-5">
						<img src={defi} className="w-1/2 " />
					</div>
				</div>
			</div>
			<ScrollTopButton />
			<Footer />
		</>
	);
};

export default DeFiIndex;
