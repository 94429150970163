// import './MainPage.css';
import React from "react";
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";

import "../../style.css";

import litepaper from "../../../assets/litepaper.png";
import Line from "../../../Components/Line/Line";
import litepaperpdf from "../../../assets/Litepaper Imperium 2.0.pdf";
import { Cta } from "../../../Components";

const Slider4 = ({
  title,
  subtitle,
  paragraph,
  position,
  pdf,
  titlepdf,
  anchor,
  poza,
}) => {
  return (
    <div className="container-custom slider-4" id={`${anchor}`}>
      <div className="grid-2-custom">
        <div className={`zona-text ${position === "left" ? "order-1" : ""}`}>
          <div>
            <h2 className="uppercase text-4xl pb-2">{title}</h2>
            <h3 className="text-2xl">{subtitle}</h3>
            <Line className={"my-4"} />
            {paragraph}
            <Link to="/litepaper" target="_BLANK">
              <Cta
                text="Explore our concept & detailed solutions"
                // href={litepaperpdf}
                target="_BLANK"
                className="text-lg block lg:inline"
              />
            </Link>
          </div>
        </div>
        <div className="flex justify-center self-center">
          <img src={poza} loading="lazy" />
        </div>
      </div>
    </div>
  );
};
export default Slider4;
