import React from "react";
import Countdown from "react-countdown";
const date = new Date("June 6, 22 13:00:00 GMT+02:00");

const Counter = () => {
	return (
		<div className="min-w-screen flex items-center justify-center px-5 py-5">
			<div className="text-white">
				<h1 className="text-3xl text-center mb-3 font-extralight font-bold">
					PRIVATE SALE IS ON!
				</h1>
				{/* <div className="text-3xl sm:text-6xl text-center flex w-full items-center justify-center">
          <div className="text-xl sm:text-2xl mr-1 font-extralight">in</div>
          <div className="sm:w-24 mx-1 p-2 bg-white text-blue-menu rounded-lg">
            <div className="font-mono leading-none">
              <Countdown
                date={Date.now() + (date.getTime() - Date.now())}
                intervalDelay={0}
                precision={3}
                renderer={(props) => (
                  <>
                    <div>{props.days}</div>
                  </>
                )}
              />
            </div>
            <div className="font-mono uppercase text-xs sm:text-sm leading-none">
              Days
            </div>
          </div>
          <div className=" sm:w-24 mx-1 p-2 bg-white text-blue-menu rounded-lg">
            <div className="font-mono leading-none">
              <Countdown
                date={Date.now() + (date.getTime() - Date.now())}
                intervalDelay={0}
                precision={3}
                renderer={(props) => (
                  <>
                    <div>{props.hours}</div>
                  </>
                )}
              />
            </div>
            <div className="font-mono uppercase text-xs sm:text-sm leading-none">
              Hours
            </div>
          </div>
          <div className=" sm:w-24 mx-1 p-2 bg-white text-blue-menu rounded-lg">
            <div className="font-mono leading-none">
              <Countdown
                date={Date.now() + (date.getTime() - Date.now())}
                intervalDelay={0}
                precision={3}
                renderer={(props) => (
                  <>
                    <div>{props.minutes}</div>
                  </>
                )}
              />
            </div>
            <div className="font-mono uppercase text-xs sm:text-sm leading-none">
              Minutes
            </div>
          </div>
          <div className="text-xl sm:text-2xl mx-1 font-extralight">and</div>
          <div className="sm:w-24 mx-1 p-2 bg-white text-blue-menu rounded-lg">
            <div className="font-mono leading-none">
              <Countdown
                date={Date.now() + (date.getTime() - Date.now())}
                intervalDelay={0}
                precision={3}
                renderer={(props) => (
                  <>
                    <div>{props.seconds}</div>
                  </>
                )}
              />
            </div>
            <div className="font-mono uppercase text-xs sm:text-sm leading-none">
              Seconds
            </div>
          </div>
        </div> */}
			</div>
		</div>
	);
};

export default Counter;
