import React from "react";
import bg from "../../assets/bg.mp4";

const HeroProfile = () => {
  return (
    <section className="relative h-64 ">
      <video
        autoPlay
        loop
        muted
        playsInline
        className="videoTag"
        id="videoclip"
      >
        <source src={bg} type="video/mp4" />
      </video>
    </section>
  );
};

export default HeroProfile;
