import "./MainPage.css";
import React, { useContext, useEffect, useRef, useState } from "react";

import ScrollTopButton from "../../Components/ScrollTopButton/ScrollTopButton";
import { LoadingContext } from "../../Providers/LoadingProvider";
import SEO from "../../Components/SEO/SEO";
import {
	Slider1,
	Slider2,
	Slider3,
	Slider4,
	Slider5,
	Slider6,
	Slider7
} from "../Sliders";
import Header from "../../Components/Header/Header";
import WhatIsImperium from "../../Components/WhatIsImperium/WhatIsImperium";
import TheCryptoIndexSection from "../../Components/TheCryptoIndexSection/TheCryptoIndexSection";
import Banner from "../../Components/Banner/Banner";
const MainPage = () => {
	const [screenSize, setScreenSize] = useState(window.innerWidth);
	const [activeItem, setActiveItem] = useState(0);
	const [scrollable, setScrollable] = useState(false);
	const [loading, setLoading] = useContext(LoadingContext);

	return (
		<div className="overflow-hidden">
			<SEO
				title="IMPERIUM - THE CRYPTO INDEX"
				description="IMPERIUM is both a collection of indices that are tracking the top cryptocurrencies worldwide and a tool for investing in them according to each asset’s market cap."
			/>
			{/* <Navbar
        active={activeItem ? activeItem.toString() : "0"}
        updateItem={updateActiveItem}
      /> */}
			<Header />
			<>
				<div className="bg__overlay"></div>
				<div className="scroller lg:mt-90px">
					<Slider1 anchor="anchor1" />
					<TheCryptoIndexSection />
					<Slider2
						title={"IMPERIUM"}
						subtitle={"Imperium Indices"}
						paragraph={
							<p className="my-2">
								IMPERIUM is both a collection of indices that are tracking the
								top cryptocurrencies worldwide and a tool for investing in them
								according to each asset’s market cap. It is a means of investing
								in the entire market, rather than any individual project, in
								order to mitigate risk and effort.
								<br />
								<br /> One Imperium can be defined as the one billionth part of
								the index it represents, and will always be priced as such. When
								purchasing one Imperium token, you will always be paying a sum
								equal to the total market cap of your index, divided by one
								billion.
							</p>
						}
						anchor="about"
					/>
					<Banner />
					<WhatIsImperium anchor="imperium" />
					<Slider3 anchor="presentation" />
					{/* <Slider4 /> */}
					<Slider7 anchor="roadmap" />
					{/* <Slider8 /> */}
					<Slider5 anchor="team" />
					<Slider6 anchor="contact" />
					<ScrollTopButton />
				</div>
			</>
		</div>
	);
};
export default MainPage;
