// import './MainPage.css';
import React, { useState, useEffect } from "react";
import { getTopRefactoring } from "../../../Api/Api";
import { Link } from "react-router-dom";
import Line from "../../../Components/Line/Line";
import { getMarketCap } from "../../../Api/Api";
import IM10 from "../../../assets/indexes/IM10.png";
import IM10alt from "../../../assets/indexes/IM10alt.png";
import IM20 from "../../../assets/indexes/IM20.png";
import IM20alt from "../../../assets/indexes/IM20alt.png";
import IM50 from "../../../assets/indexes/IM50.png";
import IM50alt from "../../../assets/indexes/IM50alt.png";
import defi from "../../../assets/logo-Defi.png";
const Slider2 = ({ title, subtitle, paragraph, anchor }) => {
	const [buttonSet, setbuttonSet] = useState(1);
	const [valmarket, setValMarket] = useState(0);
	const [marketCap, setMarketCap] = useState(0);

	useEffect(() => {
		getMarketCap().then(response => {
			setMarketCap(parseFloat(response.value / 1000000000).toFixed(2));
		});
	});

	useEffect(() => {
		getTopRefactoring().then(response => {
			setValMarket(response.top);
		});
	}, []);
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
			/* you can also use 'auto' behaviour
				 in place of 'smooth' */
		});
	};
	return (
		<div className="container-custom slider-2" id={`${anchor}`}>
			{/* {console.log(valmarket)} */}
			<div className="grid grid-cols-1 lg:grid-cols-2 gap-5  xl:gap-20">
				<div className="pt-10">
					<div className=" butoane grid grid-cols-1 sm:grid-cols-3 justify-between gap-2 sm:gap-0 pb-10">
						<div className=" sm:px-2 2xl:px-8 col-span-1">
							<button
								onClick={() => {
									setbuttonSet(1);
								}}
								className={` flex justify-center py-3  w-full hover:bg-black-b-hover rounded-lg font-medium text-white sm:text-xs xl:text-base duration-300 ${
									buttonSet == 1 ? "bg-blue-button" : "bg-c-profile"
								}`}
							>
								Top Crypto Indices
							</button>
						</div>
						<div className=" sm:px-2 2xl:px-8 col-span-1">
							<button
								onClick={() => {
									setbuttonSet(0);
								}}
								className={` flex justify-center py-3 w-full hover:bg-black-b-hover rounded-lg font-medium text-white sm:text-xs xl:text-base duration-300 ${
									buttonSet == 0 ? "bg-blue-button" : "bg-c-profile"
								}`}
							>
								Top Altcoins Indices
							</button>
						</div>

						<div className=" sm:px-2 2xl:px-8 col-span-1">
							<button
								onClick={() => {
									setbuttonSet(2);
								}}
								className={` flex justify-center py-3 w-full hover:bg-black-b-hover rounded-lg font-medium text-white sm:text-xs xl:text-base duration-300 ${
									buttonSet == 2 ? "bg-blue-button" : "bg-c-profile"
								}`}
							>
								Sectoral Indices
							</button>
						</div>
					</div>
					{buttonSet == 1 ? (
						<>
							<div className="col-span-2 2xl:col-span-1 grid-facilitati grid-facilitati-desktop gap-5">
								<Link to="/IM10" onClick={scrollToTop}>
									<div className="flex flex-col justify-center items-center  mb-4">
										<div className="flex flex-col pb-2 border-b-2 w-full ">
											<img src={IM10} className="w-32 h-32 mb-2 self-center" />
											<p className="mt-2 text-white text-center">IMPERIUM 10</p>
										</div>
										<div className="text-xs flex justify-between text-white p-2 w-full">
											<p>M Cap </p>{" "}
											{valmarket ? (
												<p>
													{" "}
													${(valmarket.top10.mcap / 1000000000).toFixed(2)} B
												</p>
											) : (
												<span className="loading-spinner"></span>
											)}
										</div>
										<div className="text-xs text-white pb-2 w-full">
											<div className="flex justify-between w-full text-lg px-2">
												<p>
													<span className="lg:hidden xl:inline-block">
														IM10 p
													</span>
													<span className="hidden lg:inline-block xl:hidden">
														P
													</span>
													rice:
												</p>
												{valmarket ? (
													<p> ${valmarket.top10.price.toFixed(2)}</p>
												) : (
													<span className="loading-spinner"></span>
												)}
											</div>
										</div>
									</div>
								</Link>
								<Link to="/IM20" onClick={scrollToTop}>
									<div className="flex flex-col justify-center items-center  mb-4">
										<div className="flex flex-col pb-2 border-b-2 w-full ">
											<img src={IM20} className="w-32 h-32 mb-2 self-center" />
											<p className="mt-2 text-white text-center">IMPERIUM 20</p>
										</div>
										<div className="text-xs flex justify-between text-white p-2 w-full">
											<p>M Cap: </p>{" "}
											{valmarket ? (
												<p>
													{" "}
													${(valmarket.top20.mcap / 1000000000).toFixed(2)} B
												</p>
											) : (
												<span className="loading-spinner"></span>
											)}
										</div>
										<div className="text-xs  text-white pb-2 w-full">
											<div className="flex justify-between w-full text-lg px-2">
												<p>
													<span className="lg:hidden xl:inline-block">
														IM20 p
													</span>
													<span className="hidden lg:inline-block xl:hidden">
														P
													</span>
													rice:
												</p>
												{valmarket ? (
													<p> ${valmarket.top20.price.toFixed(2)}</p>
												) : (
													<span className="loading-spinner"></span>
												)}
											</div>
										</div>
									</div>
								</Link>
								<Link to="/IM50" onClick={scrollToTop}>
									<div className="flex flex-col justify-center items-center  mb-4">
										<div className="flex flex-col pb-2 border-b-2 w-full ">
											<img src={IM50} className="w-32 h-32 mb-2 self-center" />
											<p className="mt-2 text-center text-white">IMPERIUM 50</p>
										</div>
										<div className="text-xs flex justify-between text-white p-2 w-full">
											<p>M Cap: </p>{" "}
											{valmarket ? (
												<p>
													{" "}
													${(valmarket.top50.mcap / 1000000000).toFixed(2)} B
												</p>
											) : (
												<span className="loading-spinner"></span>
											)}
										</div>
										<div className="text-xs  text-white pb-2 w-full">
											<div className="flex justify-between w-full text-lg px-2">
												<p>
													<span className="lg:hidden xl:inline-block">
														IM50 p
													</span>
													<span className="hidden lg:inline-block xl:hidden">
														P
													</span>
													rice:
												</p>
												{valmarket ? (
													<p> ${valmarket.top50.price.toFixed(2)}</p>
												) : (
													<span className="loading-spinner"></span>
												)}
											</div>
										</div>
									</div>
								</Link>
							</div>
						</>
					) : (
						""
					)}
					{buttonSet == 0 ? (
						<>
							<div className="col-span-2 2xl:col-span-1 grid-facilitati grid-facilitati-desktop gap-5">
								<Link to="/IM10alt" onClick={scrollToTop}>
									<div className="flex flex-col justify-center items-center  mb-4">
										<div className="flex flex-col pb-2 border-b-2 w-full ">
											<img
												src={IM10alt}
												className="w-32 h-32 mb-2 self-center"
											/>
											<p className="mt-2 text-white text-center">
												IMPERIUM 10alt
											</p>
										</div>
										<div className="text-xs flex justify-between text-white p-2 w-full">
											<p>M Cap: </p>{" "}
											{valmarket ? (
												<p>
													{" "}
													${(valmarket.top10alt.mcap / 1000000000).toFixed(2)} B
												</p>
											) : (
												<span className="loading-spinner"></span>
											)}
										</div>
										<div className="text-xs  text-white pb-2 w-full">
											<div className="flex justify-between w-full text-lg px-2">
												<p>
													<span className="lg:hidden xl:inline-block">
														IM10 alt p
													</span>
													<span className="hidden lg:inline-block xl:hidden">
														P
													</span>
													rice:
												</p>
												{valmarket ? (
													<p> ${valmarket.top10alt.price.toFixed(2)}</p>
												) : (
													<span className="loading-spinner"></span>
												)}
											</div>
										</div>
									</div>
								</Link>
								<Link to="/IM20alt" onClick={scrollToTop}>
									<div className="flex flex-col justify-center items-center  mb-4">
										<div className="flex flex-col pb-2 border-b-2 w-full ">
											<img
												src={IM20alt}
												className="w-32 h-32 mb-2 self-center"
											/>
											<p className="mt-2 text-white text-center">
												IMPERIUM 20alt
											</p>
										</div>
										<div className="text-xs flex justify-between text-white p-2 w-full items-center">
											<p>M Cap: </p>{" "}
											{valmarket ? (
												<p>
													{" "}
													${(valmarket.top20alt.mcap / 1000000000).toFixed(2)} B
												</p>
											) : (
												<span className="loading-spinner"></span>
											)}
										</div>
										<div className="text-xs  text-white pb-2 w-full">
											<div className="flex justify-between w-full text-lg px-2">
												<p>
													<span className="lg:hidden xl:inline-block">
														IM20 alt p
													</span>
													<span className="hidden lg:inline-block xl:hidden">
														P
													</span>
													rice:
												</p>
												{valmarket ? (
													<p> ${valmarket.top20alt.price.toFixed(2)}</p>
												) : (
													<span className="loading-spinner"></span>
												)}
											</div>
										</div>
									</div>
								</Link>

								<Link to="/IM50alt" onClick={scrollToTop}>
									<div className=" flex flex-col justify-center items-center  mb-4">
										<div className="flex flex-col pb-2 border-b-2 w-full ">
											<img
												src={IM50alt}
												className="w-32 h-32 mb-2 self-center"
											/>
											<p className="mt-2 text-white text-center">
												IMPERIUM 50alt
											</p>
										</div>
										<div className="text-xs flex justify-between text-white p-2 w-full">
											<p>M Cap 50: </p>{" "}
											{valmarket ? (
												<p>
													{" "}
													${(valmarket.top50alt.mcap / 1000000000).toFixed(2)} B
												</p>
											) : (
												<span className="loading-spinner"></span>
											)}
										</div>
										<div className="text-xs  text-white pb-2 w-full">
											<div className="flex justify-between w-full text-lg px-2">
												<p>
													<span className="lg:hidden xl:inline-block">
														IM50 alt p
													</span>
													<span className="hidden lg:inline-block xl:hidden">
														P
													</span>
													rice:
												</p>
												{valmarket ? (
													<p> ${valmarket.top50alt.price.toFixed(2)}</p>
												) : (
													<span className="loading-spinner"></span>
												)}
											</div>
										</div>
									</div>
								</Link>
							</div>
						</>
					) : (
						""
					)}
					{buttonSet == 2 ? (
						<div
							className={`text-white    hover:opacity-100 duration-300 px-4 sm:px-6 `}
						>
							<div className="flex justify-center ">
								{marketCap ? (
									<>
										<Link to="/DeFi-Index" onClick={scrollToTop}>
											<div className="flex flex-col items-center font-bold py-0.5 normal-case pb-5">
												<div className="max-w-220px border-b-2 border-white">
													<img src={defi} className="  px-10" />
													<p className="text-center font-bold pb-2">
														IMPERIUM DeFi
													</p>
												</div>
											</div>
										</Link>
									</>
								) : (
									<>
										<div className="text-white flex items-center gap-2">
											IMΣ : <span className="loading-spinner"></span>
										</div>
									</>
								)}
							</div>
						</div>
					) : (
						""
					)}
				</div>
				<div className="col-span-1 flex flex-col justify-center pt-5">
					<div className="flex flex-col">
						<h2>{title}</h2>
						<h3 className="text-white">{subtitle}</h3>
						<Line className={"my-4"} />
						<div className="text-white">{paragraph}</div>
						<div className="flex items-center">
							<a
								className=" bg-blue-button text-sm mt-5 text-white hover:bg-blue-b-h rounded-lg px-3 py-2"
								href="https://rekvizitai.vz.lt/en/company/boskalma/"
								target="_blank"
							>
								Crypto Authorization
							</a>
						</div>
					</div>
				</div>
				{/* MOBILE */}
				{/* <div className="grid-facilitati grid-facilitati-mobile">
          <div>
            <div className="flex flex-col justify-center items-center p-2 md:px-2 mb-4">
              <img src={IM10} className="w-32 h-32 mb-2 self-center" />
              <p className="mt-2 text-white  text-center">
                TOP 10 CRYPTO INDEX
              </p>
            </div>
          </div>
          <div>
            <div className="flex flex-col justify-center items-center p-2 md:px-2 mb-4">
              <img src={IM10alt} className="w-32 h-32 mb-2 self-center" />
              <p className="mt-2 text-white text-center">
                TOP 10 ALTCOINS INDEX
              </p>
            </div>
          </div>
          <div>
            <div className="flex flex-col justify-center items-center p-2 md:px-2 mb-4">
              <img src={IM20} className="w-32 h-32 mb-2 self-center" />

              <p className="mt-2 text-center text-white">TOP 20 CRYPTO INDEX</p>
            </div>
          </div>
          <div>
            <div className=" flex flex-col justify-center items-center p-2 md:px-2 mb-4">
              <img src={IM20alt} className="w-32 h-32 mb-2 self-center" />

              <p className="mt-2 text-white text-center">
                TOP 20 ALTCOINS INDEX
              </p>
            </div>
          </div>
          <div>
            <div className="flex flex-col justify-center items-center p-2 md:px-2 mb-4">
              <img src={IM50} className="w-32 h-32 mb-2 self-center" />

              <p className="mt-2 text-white text-center">TOP 50 CRYPTO INDEX</p>
            </div>
          </div>

          <div>
            <div className=" flex flex-col justify-center items-center p-2 md:px-2 mb-4">
              <img src={IM50alt} className="w-32 h-32 mb-2 self-center" />

              <p className="mt-2 text-white text-center">
                TOP 50 ALTCOINS INDEX
              </p>
            </div>
          </div>
        </div> */}
			</div>
		</div>
	);
};
export default Slider2;
