import React, { useContext, useState, useEffect } from "react";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { UserContext } from "../../Providers/UserProvider";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import logo from "../../assets/logonou_footer.png";
import { HashLink as Link } from "react-router-hash-link";
import avatar from "../../assets/default-avatar.jpg";
import TopBar from "../TopBar/TopBar";
import LexPool from "../../assets/LEXPool1.gif";
import LexPoolMobile from "../../assets/LEXSeed_mobile.gif";
import { handleLogout } from "../../Utils";
import CookieBot from "react-cookiebot/lib/CookieBot";

const menu = [
	{
		name: "Home",
		href: "/",
		submenu: [
			{ name: "About us", href: "/#about" },
			{ name: "Imperium", href: "/#imperium" },
			{ name: "Presentation", href: "/#presentation" },
			{ name: "Roadmap", href: "/#roadmap" },
			{ name: "Team", href: "/#team" }
			// { name: "Contact", href: "/#contact" },
		]
	},
	{
		name: "INDICES",
		href: "/",
		submenu: [
			{ name: "Top Crypto Indices", href: "", title: true },
			{ name: "IM10", href: "/IM10", top: true },
			{ name: "IM20", href: "/IM20", top: true },
			{ name: "IM50", href: "/IM50", top: true },

			{ name: "Top Altcoins Indices", href: "", title: true },
			,
			{ name: "IM10alt", href: "/IM10alt", top: true },
			{ name: "IM20alt", href: "/IM20alt", top: true },
			{ name: "IM50alt", href: "/IM50alt", top: true },

			{ name: "Sectoral Indices ", href: "", title: true },
			{ name: "Imperium DeFi Index", href: "/DeFi-Index", top: true },

			{ name: "Imperium Reference Index", href: "", title: true },
			{ name: "IMΣ", href: "/Reference-Index", top: true }
			// { name: "Contact", href: "/#contact" },
		]
	},
	{
		name: "Lex",
		href: "/lex",
		submenu: [
			{ name: "Token", href: "/lex#token" },
			{ name: "Tokenomics", href: "/lex#tokenomics" },
			{ name: "Litepaper", href: "/lex#litepaper" },
			{ name: "Whitepaper ", href: "/lex#whitepaper" }
		]
	},
	{
		name: "MVP SIMULATOR",
		href: "/mvp",
		submenu: [
			{ name: "Presentation", href: "/mvp/#presentation" },
			{ name: "Investment Simulation", href: "/mvp/#investment" }
		]
	},
	// {
	//   name: "Imperium",
	//   href: "/imperium",
	//   submenu: [
	//     { name: "What is Imperium", href: "/imperium#what-is-imperium" },
	//     { name: "Whitepaper", href: "/imperium#whitepaper" },
	//     { name: "Imperium", href: "/imperium#imperium" },
	//   ],
	// },
	{
		name: "How It Works",
		href: "/how-it-works",
		submenu: [
			{ name: "How It Works", href: "/how-it-works/#how-it-work" },
			{ name: "Video Explainers", href: "/how-it-works/#video-explainer" }
		]
	},
	{
		name: "Blog & Social",
		href: "/blog-social"
	}
];

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const scrollToTop = () => {
	window.scrollTo({
		top: 0,
		behavior: "smooth"
		/* you can also use 'auto' behaviour
       in place of 'smooth' */
	});
};
const Header = () => {
	const [hasCookieBot, setHasCookieBot] = useState(undefined);
	const domainGroupId = "80938ef2-e61c-4271-b897-bad19fa00bfa";
	const [user, setUser] = useContext(UserContext);
	const [scrollPosition, setScrollPosition] = useState(0);
	const handleScroll = () => {
		const position = window.pageYOffset;
		setScrollPosition(position);
	};
	useEffect(() => {
		window.addEventListener("scroll", handleScroll, { passive: true });
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<>
			<Popover className={` fixed z-110 w-full uppercase`}>
				{/* <TopBar scrollPosition={scrollPosition} /> */}
				<CookieBot domainGroupId={domainGroupId} />
				<div
					className={`bg-blue-menu mx-auto px-4 sm:px-6 ${
						scrollPosition === 0 ? "opacity-100" : "opacity-50"
					}  hover:opacity-100 duration-300`}
				>
					<div className="flex justify-between items-center py-2 ">
						<div className="l">
							<Link to="/" onClick={scrollToTop}>
								<span className="sr-only">Workflow</span>
								<img
									className={` duration-300 ${
										scrollPosition === 0 ? " w-9 sm:w-16" : "w-8 sm:w-10"
									}`}
									src={logo}
									alt=""
								/>
							</Link>
						</div>

						<Popover.Group as="nav" className="hidden lg:flex gap-3 xl:gap-4">
							{menu.map((item, index) => (
								<div className="button-menu relative" key={index}>
									<Link
										to={item.href}
										onClick={scrollToTop}
										className={`group  rounded-md inline-flex items-center hover:text-gray-500 text-lg font-medium ${
											window.location.pathname == item.href
												? "text-gray-500"
												: "text-white"
										}`}
									>
										<span className="uppercase text-base xl:text-lg">
											{item.name}
										</span>
										{item.submenu ? (
											<ChevronDownIcon
												className="ml-2 h-5 w-5 rotate-180 hover:text-gray-500"
												aria-hidden="true"
											/>
										) : (
											""
										)}
									</Link>
									<div className="modal-submenu absolute z-10 -ml-4 pt-2 transform px-2 w-screen max-w-xs sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
										<div
											className={` ${
												item.submenu ? "border border-gray-500 " : ""
											} rounded-lg shadow-lg ring-1  ring-black ring-opacity-5 overflow-hidden`}
										>
											<div className="relative grid  bg-blue-menu   ">
												{item.submenu ? (
													<div>
														{item.submenu.map((itm, indx) => (
															<>
																{itm.title ? (
																	<p className="p-3 text-white text-lg">
																		{itm.name}
																	</p>
																) : (
																	<Link
																		onClick={itm.top ? scrollToTop : ""}
																		key={indx}
																		to={itm.href}
																		className={`p-2 flex items-start  hover:bg-gray-50 text-white hover:text-blue-menu `}
																	>
																		<div className="ml-4">
																			<p className="text-base font-medium ">
																				{itm.name}
																			</p>
																		</div>
																	</Link>
																)}
															</>
														))}
													</div>
												) : (
													""
												)}
											</div>
										</div>
									</div>
								</div>
							))}
						</Popover.Group>
						<div className="flex items-center ">
							<div className="-mr-2 -my-2 lg:hidden">
								<Popover.Button
									className={`p-2 inline-flex items-center justify-center text-gray-400 `}
								>
									<span className="sr-only">Open menu</span>
									<MenuIcon className="h-6 w-6" aria-hidden="true" />
								</Popover.Button>
							</div>
							{user ? (
								<>
									{/* <div className="bg-back-profile rounded-xl py-2 px-3 flex items-center gap-4 normal-case">
                <img src={avatar} width={25} className="rounded-sm" />
                <div className="flex gap-2 justify-center items-center">
                  <div className="flex flex-col gap-0.5 text-white">
                    <h5 className="text-xs text-gray-500 font-medium">ERN</h5>
                    <p className="text-xs text-right font-medium">0</p>
                  </div>
                  <div className="flex flex-col gap-0.5 text-white">
                    <h5 className="text-xs text-gray-500 font-medium">wETH</h5>
                    <p className="text-xs text-right font-medium">0</p>
                  </div>
                  <div className="flex flex-col gap-0.5 text-white">
                    <h5 className="text-xs text-gray-500 font-medium">
                      Stones
                    </h5>
                    <p className="text-xs text-right font-medium">0</p>
                  </div>
                </div>
              </div> */}
									<Link
										to="/dashboard"
										className="hidden lg:block  ml-2 xl:ml-8 whitespace-nowrap inline-flex items-center justify-center px-2 xl:px-4 py-2 border border-transparent rounded-md shadow-sm text-sm xl:text-base font-medium text-white bg-blue-button hover:bg-blue-b-h"
									>
										Dashboard
									</Link>
									<div
										onClick={handleLogout}
										className="hidden lg:block cursor-pointer ml-2 xl:ml-8 whitespace-nowrap inline-flex items-center justify-center px-2 xl:px-4 py-2 border border-transparent rounded-md shadow-sm text-sm xl:text-base font-medium text-white bg-blue-button hover:bg-blue-b-h"
									>
										Log out
									</div>
								</>
							) : (
								<>
									<Link
										to="/login"
										className="hidden lg:block ml-4 lg:ml-0 whitespace-nowrap text-base font-medium text-white hover:text-gray-400"
									>
										Login
									</Link>
									<Link
										to="/register"
										className="hidden lg:block  ml-2 xl:ml-8 whitespace-nowrap inline-flex items-center justify-center px-2 xl:px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-button hover:bg-blue-b-h"
									>
										Register
									</Link>
								</>
							)}
						</div>
					</div>
					<div className="flex justify-center -mt-2 sm:-mt-4">
						<a
							href="https://herityseed.io/projects/details/27851"
							target="_blank"
						>
							<img
								src={LexPool}
								alt="LexPool"
								className={`hidden sm:block duration-200 ${
									scrollPosition === 0 ? " sm:h-14 " : " sm:h-12"
								} `}
							/>
							<img
								src={LexPoolMobile}
								alt="LexPoolMobile"
								className={`block sm:hidden  duration-200 ${
									scrollPosition === 0 ? " sm:h-14 " : " sm:h-12"
								} `}
							/>
						</a>
					</div>
				</div>

				<Transition
					as={Fragment}
					enter="duration-200 ease-out"
					enterFrom="opacity-0 scale-95"
					enterTo="opacity-100 scale-100"
					leave="duration-100 ease-in"
					leaveFrom="opacity-100 scale-100"
					leaveTo="opacity-0 scale-95"
				>
					<Popover.Panel
						focus
						className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden overflow-y-scroll h-screen"
					>
						<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-blue-menu divide-y-2 divide-gray-500">
							<div className="pt-5 pb-6 px-5">
								<div className="flex items-center justify-between">
									<div>
										<img className=" h-14" src={logo} alt="Workflow" />
									</div>
									<div className="-mr-2">
										<Popover.Button className="bg-blue-button rounded-md p-1 inline-flex items-center justify-center text-blue-menu ">
											<span className="sr-only">Close menu</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</Popover.Button>
									</div>
								</div>

								<div className="mt-6">
									{user ? (
										<div className="flex justify-center gap-2">
											<Link
												to="/dashboard"
												className="block whitespace-nowrap inline-flex items-center justify-center px-5  py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-button hover:bg-blue-b-h"
											>
												Dashboard
											</Link>
											<div
												onClick={handleLogout}
												className="cursor-pointer ml-2 xl:ml-8 whitespace-nowrap inline-flex items-center justify-center px-2 xl:px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-button hover:bg-blue-b-h"
											>
												Log out
											</div>
										</div>
									) : (
										<div className="py-6 px-5 space-y-6">
											<div>
												<Link
													to="/login"
													className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-blue-menu bg-blue-button hover:bg-blue-b-h"
												>
													Login
												</Link>
												<p className="mt-6 text-center text-base font-medium text-gray-500">
													Don't have an account?{" "}
													<Link
														to="/register"
														className="text-blue-button hover:text-blue-b-h"
													>
														Register
													</Link>
												</p>
											</div>
										</div>
									)}
									<nav className="grid gap-y-2">
										{menu.map((item, index) => (
											<>
												{item.submenu ? (
													<div className="" key={index}>
														<Link
															key={item.name}
															to={item.href}
															className="-ml-3 p-3 flex items-center rounded-md "
														>
															<span className="ml-3 text-base font-medium text-white">
																{item.name}
															</span>
														</Link>
														<div className="submenu text-white ml-6 flex flex-col">
															{item.submenu.map((itm, ind) => (
																<>
																	{itm.title ? (
																		<p className="p-3 text-white text-lg">
																			{itm.name}
																		</p>
																	) : (
																		<Link
																			onClick={itm.top ? scrollToTop : ""}
																			key={ind}
																			to={itm.href}
																			className={`p-2 flex items-start  hover:bg-gray-50 text-white hover:text-blue-menu `}
																		>
																			<div className="ml-4">
																				<p className="text-base font-medium ">
																					{itm.name}
																				</p>
																			</div>
																		</Link>
																	)}
																</>
															))}
														</div>
													</div>
												) : (
													<Link
														key={index}
														to={item.href}
														className="-m-3 p-3 flex items-center rounded-md "
													>
														<span className="ml-3 text-base font-medium text-white">
															{item.name}
														</span>
													</Link>
												)}
											</>
										))}
									</nav>
								</div>
							</div>
						</div>
					</Popover.Panel>
				</Transition>
			</Popover>
		</>
	);
};
export default Header;
