import React from "react";

import corobea from "../../../assets/team/andrei-corobea.png";
import liviu from "../../../assets/team/liviu-manda.png";
import vlad from "../../../assets/team/vlad-manda.png";
import roman from "../../../assets/team/roman-korzh.png";
import coings from "../../../assets/team/coinsilieri.png";
import misu from "../../../assets/team/misu-negritoiu.png";
import diaconescu from "../../../assets/team/andrei-diaconescu.png";
import cristina from "../../../assets/team/cristina-balaceanu.png";
import dorel from "../../../assets/team/dorel-paraschiv.png";
import shufti from "../../../assets/team/shufti-pro.png";
import gofaizen from "../../../assets/team/gofaizen-sherle.png";
import lewben from "../../../assets/team/Lewben.png";
import nordgain from "../../../assets/team/Nordgain.png";

import nistor from "../../../assets/team/cristian-nistor.png";
import stoian from "../../../assets/team/razvan-stoian.png";
import balut from "../../../assets/team/stefan-balut.png";
import panoiu from "../../../assets/team/radu-panoiu.png";
import tudor from "../../../assets/team/tudor-coman.png";
import toni from "../../../assets/team/toni-androniuc.png";
import madalina_avram from "../../../assets/team/madalina-avram.jpg";
import nicolae from "../../../assets/team/nicolae-moldoveanu.png";
import zfort from "../../../assets/team/zfort.png";
import smarters from "../../../assets/team/smarters.png";
import wiscobus from "../../../assets/team/Wiscobus Ntukuyoh.webp";

import SocialMediaTeam from "../../../Components/SocialMediaTeam/SocialMediaTeam";
import tza from "../../../assets/team/tuca-zbarcea-asociatii.png";
import inntech from "../../../assets/team/inntech.png";
import voxility from "../../../assets/team/voxility.png";

import alfred from "../../../assets/team/alfred-taudes.png";
import antony from "../../../assets/team/antony-welfare.png";

const Slider5 = ({ anchor }) => {
  return (
    <div
      className="container-custom flex flex-col align-items-center slider_5"
      id={`${anchor}`}
    >
      <div className="md:mt-20 xl:my-auto">
        <div className="flex flex-col">
          <h2 className="text-center uppercase font-bolder mb-2 sm:mb-8 mt-4 sm:mt-8">
            Team
          </h2>

          <h3 className="text-center uppercase font-bolder mb-2 sm:mb-8  sm:mt-8">
            CORE TEAM
          </h3>
          <div className="flex flex-col xl:flex-row xl:justify-around text-white md:mx-20">
            {/* <div className="flex flex-col items-center xl:w-1/3 md:px-12 sm:mb-8">
							<img src={corobea} className="team-image" loading="lazy" />
							<p className="sub-header my-4">Andrei Corobea-Birceanu</p>
							<p className="mb-2 sm:mb-4 blue text-center">
								CO-FOUNDER & Chief Strategy Officer
							</p>
							<SocialMediaTeam linkedin="https://www.linkedin.com/in/andrei-corobea-birceanu-0173b312a/" />
						</div> */}
            <div className="flex flex-col items-center xl:w-1/3 md:px-12 sm:mb-8">
              <img src={liviu} className="team-image" loading="lazy" />
              <p className="sub-header my-4">Liviu Manda</p>
              <p className="mb-2 sm:mb-4 blue text-center">
                CO-FOUNDER & Chief Executive Officer
              </p>
              <SocialMediaTeam
                linkedin="https://www.linkedin.com/in/liviu-manda-2741a1232/"
                twitter="https://twitter.com/manda_liviu"
              />
            </div>
            <div className="flex flex-col items-center xl:w-1/3 md:px-12 sm:mb-8">
              <img src={vlad} className="team-image" loading="lazy" />
              <p className="sub-header my-4">Vlad Manda</p>
              <p className="mb-2 sm:mb-4 blue text-center">
                CO-FOUNDER & Chief Design Officer
              </p>
              <SocialMediaTeam
                linkedin="https://www.linkedin.com/in/vlad-manda-b3a92817b/"
                twitter="https://twitter.com/vlad_manda"
              />
            </div>
          </div>

          <h3 className="text-center uppercase font-bolder mb-2 sm:mb-8  sm:mt-8">
            TEAM
          </h3>
          <div className="flex flex-col xl:flex-row xl:justify-around text-white md:mx-20">
            <div className="flex flex-col items-center xl:w-1/3 md:px-12 sm:mb-8">
              <img src={tudor} className="team-image" loading="lazy" />
              <p className="sub-header my-4">Tudor Coman</p>
              <p className="mb-2 sm:mb-4 blue text-center">
                Business Developer
              </p>
              <SocialMediaTeam linkedin="https://www.linkedin.com/company/imperium-index/" />
            </div>
            <div className="flex flex-col items-center xl:w-1/3 md:px-12 mb-8">
              <img src={roman} className="team-image" loading="lazy" />
              <p className="sub-header my-4">Roman Korzh</p>
              <p className="mb-4 blue text-center">Chief Technology Officer</p>
              <SocialMediaTeam linkedin="https://www.linkedin.com/in/romankorzh/" />
            </div>
            <div className="flex flex-col items-center xl:w-1/3 md:px-12 sm:mb-8">
              <img src={panoiu} className="team-image" loading="lazy" />
              <p className="sub-header my-4">Radu Panoiu</p>
              <p className="mb-2 sm:mb-4 blue text-center">
                Chief Operating Officer
              </p>
              <SocialMediaTeam linkedin="https://www.linkedin.com/in/radu-panoiu-72157995/" />
            </div>
          </div>
          <div className="flex flex-col xl:flex-row xl:justify-around text-white md:mx-20">
            <div className="flex flex-col items-center xl:w-1/3 md:px-12 sm:mb-8">
              <img src={nistor} className="team-image" loading="lazy" />
              <p className="sub-header my-4">Cristian Nistor</p>
              <p className="mb-2 sm:mb-4 blue text-center">
                Chief Communications Officer
              </p>
              <SocialMediaTeam linkedin="https://www.linkedin.com/in/nistorcristian/" />
            </div>

            <div className="flex flex-col items-center xl:w-1/3 md:px-12 sm:mb-8">
              <img src={nicolae} className="team-image" loading="lazy" />
              <p className="sub-header my-4">Nic Moldoveanu</p>
              <p className="mb-2 sm:mb-4 blue text-center">Graphic Designer</p>
              <SocialMediaTeam linkedin="https://www.linkedin.com/in/nicmoldoveanu/" />
            </div>

            <div className="flex flex-col items-center xl:w-1/3 md:px-12 sm:mb-8">
              <img
                src={madalina_avram}
                className="team-image rounded-full"
                loading="lazy"
              />
              <p className="sub-header my-4">Madalina Avram</p>
              <p className="mb-2 sm:mb-4 blue text-center">Public Relations</p>
              <SocialMediaTeam linkedin="https://www.linkedin.com/company/imperium-index" />
            </div>

            <div className="flex flex-col items-center xl:w-1/3 md:px-12 sm:mb-8">
              <img
                src={wiscobus}
                className="team-image rounded-full"
                loading="lazy"
              />
              <p className="sub-header my-4">Wiscobus Ntukuyoh</p>
              <p className="mb-2 sm:mb-4 blue text-center">
                Community Moderator
              </p>
              <SocialMediaTeam linkedin="https://www.linkedin.com/in/wiscobus-ntukuyoh-98258b164/" />
            </div>
          </div>
          {/* <h3 className="text-center uppercase font-bolder mb-8 mt-8">
            LEAD MANAGEMENT
          </h3>
          <div className="flex flex-col xl:flex-row xl:justify-around text-white md:mx-20">
            <div className="flex flex-col items-center xl:w-1/3 md:px-12 mb-8">
              <img src={roman} className="team-image" loading="lazy" />
              <p className="sub-header my-4">Roman Korzh</p>
              <p className="mb-4 blue text-center">CTO</p>
              <SocialMediaTeam linkedin="https://www.linkedin.com/in/romankorzh/" />
            </div>
            <div className="flex flex-col items-center xl:w-1/3 md:px-12 mb-8">
              <img src={coings} className="team-image" loading="lazy" />
              <p className="sub-header my-4">COINsiglieri</p>
              <p className="mb-4 blue text-center">PROJECT MANAGEMENT</p>
              <SocialMediaTeam linkedin="https://www.linkedin.com/company/coinsiglieri/" />
            </div>

            <div className="flex flex-col items-center xl:w-1/3 md:px-12 mb-8">
              <img src={tza} className="team-image" loading="lazy" />
              <p className="sub-header my-4">TZA Lawyers</p>
              <p className="mb-4 blue text-center">LEGAL</p>
              <SocialMediaTeam linkedin="https://www.linkedin.com/company/tuca-zbarcea-&-asociatii/" />
            </div>
          </div> */}

          <h3 className="text-center uppercase font-bolder mb-8 mt-8">
            BOARD OF ADVISORS
          </h3>

          <div className="flex flex-col xl:flex-row xl:justify-around text-white md:mx-20">
            {/* <div className="flex flex-col items-center xl:w-1/3 md:px-12 mb-8">
              <img src={misu} className="team-image" loading="lazy" />
              <p className="sub-header my-4">Misu Negritoiu</p>
              <p className="mb-4 blue text-center">Former Bank President</p>
              <SocialMediaTeam linkedin="https://www.linkedin.com/in/misunegritoiu/" />
            </div> */}
          </div>

          <div className="flex flex-col xl:flex-row xl:justify-around text-white md:mx-20">
            <div className="flex flex-col items-center xl:w-1/3 md:px-12 mb-8">
              <img src={alfred} className="team-image" loading="lazy" />
              <p className="sub-header my-4">Alfred Taudes</p>
              <p className="mb-4 blue text-center">
                Entrepreneurial Professor & Cryptoeconomics Researcher WU
                (Vienna University of Economics and Business)
              </p>
              <SocialMediaTeam linkedin="https://www.linkedin.com/in/alfredtaudes/" />
            </div>
            {/* <div className="flex flex-col items-center xl:w-1/3 md:px-12 mb-8">
              <img src={diaconescu} className="team-image" loading="lazy" />
              <p className="sub-header my-4">Andrei Diaconescu</p>
              <p className="mb-4 blue text-center">
                Co-founder One United Properties
              </p>
              <SocialMediaTeam site="https://www.one.ro/en" />
            </div> */}

            <div className="flex flex-col items-center xl:w-1/3 md:px-12 mb-8">
              <img src={dorel} className="team-image" loading="lazy" />
              <p className="sub-header my-4">Dorel Paraschiv</p>
              <p className="mb-4 blue text-center">
                Prof. Univ. Dr. ASE Bucharest
              </p>
              <SocialMediaTeam site="https://alumni.ase.ro/despre.php" />
            </div>
            {/* <div className="flex flex-col items-center xl:w-1/3 md:px-12 mb-8">
              <img src={onetiu} className="team-image" />
              <p className="sub-header my-4">Cristina Onetiu</p>
              <p className="mb-4 blue text-center">ADVISOR</p>
              <SocialMediaTeam />
            </div> */}
          </div>

          <h3 className="text-center uppercase font-bolder mb-8 mt-8">
            EXECUTIVE PARTNERS
          </h3>
          <div className="flex flex-col xl:flex-row xl:justify-around text-white md:mx-20">
            <div className="flex flex-col items-center xl:w-1/3 md:px-12 mb-8">
              <img src={shufti} className="team-image" loading="lazy" />
              <p className="sub-header my-4">ShuftiPro</p>
              <p className="mb-4 blue text-center">KYC & KYB / AML</p>
              <SocialMediaTeam linkedin="https://shuftipro.com/" />
            </div>
            <div className="flex flex-col items-center xl:w-1/3 md:px-12 mb-8">
              <img src={zfort} className="team-image" loading="lazy" />
              <p className="sub-header my-4">Zfort Group</p>
              <p className="mb-4 blue text-center">SOFTWARE DEVELOPMENT</p>
              <SocialMediaTeam linkedin="https://www.linkedin.com/company/zfort-group/" />
            </div>
            <div className="flex flex-col items-center xl:w-1/3 md:px-12 mb-8">
              <img src={lewben} className="team-image" loading="lazy" />
              <p className="sub-header my-4">LEWBEN</p>
              <p className="mb-4 blue text-center">LEGAL </p>
              <SocialMediaTeam linkedin="https://www.linkedin.com/company/lewben/" />
            </div>
            <div className="flex flex-col items-center xl:w-1/3 md:px-12 mb-8">
              <img src={nordgain} className="team-image" loading="lazy" />
              <p className="sub-header my-4 uppercase">Nordgain</p>
              <p className="mb-4 blue text-center">ACCOUNTING</p>
              <SocialMediaTeam linkedin="https://www.linkedin.com/company/nordgain/" />
            </div>
            {/* <div className="flex flex-col items-center xl:w-1/3 md:px-12 mb-8">
              <img src={gofaizen} className="team-image" loading="lazy" />
              <p className="sub-header my-4">Gofaizen & Sherle</p>
              <p className="mb-4 blue text-center">LEGAL</p>
              <SocialMediaTeam linkedin="https://gofaizen-sherle.com/ " />
            </div> */}
            {/* <div className="flex flex-col items-center xl:w-1/3 md:px-12 mb-8">
              <img src={coings} className="team-image" loading="lazy" />
              <p className="sub-header my-4">COINsiglieri</p>
              <p className="mb-4 blue text-center">PROJECT MANAGEMENT</p>
              <SocialMediaTeam linkedin="https://www.linkedin.com/company/coinsiglieri/" />
            </div> */}
          </div>

          <h3 className="text-center uppercase font-bolder mb-8 mt-8">
            PARTNERS
          </h3>
          <div className="flex flex-col xl:flex-row xl:justify-around text-white md:mx-20">
            <div className="flex flex-col items-center xl:w-1/3 md:px-12 mb-8">
              <img src={voxility} className="team-image" loading="lazy" />
              <p className="sub-header my-4">Voxility</p>
              <p className="mb-4 blue text-center">INFRASTRUCTURE</p>
              <SocialMediaTeam linkedin="https://www.linkedin.com/company/voxility/" />
            </div>

            <div className="flex flex-col items-center xl:w-1/3 md:px-12 mb-8">
              <img src={inntech} className="team-image" loading="lazy" />
              <p className="sub-header my-4">INNTECH</p>
              <p className="mb-4 blue text-center">WEB DEVELOPMENT</p>
              <SocialMediaTeam linkedin="https://www.linkedin.com/company/inntechro/" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider5;
