import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { auth } from "../../Api";
import { updateUser } from "../../Api/Api";
import { Footer } from "../../Components";
import Header from "../../Components/Header/Header";
import { UserContext } from "../../Providers";
import AvatarProfile from "../Profile/AvatarProfile";

import ButtonsContent from "../Profile/ButtonsContent";
import HeroProfile from "../Profile/HeroProfile";
import Overview from "../Profile/Overview";

const ProfileEdit = () => {
  const [user] = useContext(UserContext);

  const [settings, setSettings] = useState({});

  useEffect(() => {
    console.log(user.user);
    setSettings(user.user);
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSettings((prevState) => ({
      ...prevState, // shallow copy all previous state
      [name]: value, // update specific key/value
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    console.log(settings);

    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        updateUser(idToken, settings).then((res) => {
          console.log(res);

          if (res != -1) {
            toast.success("Success!", {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("Error!", {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
      })
      .catch(function (error) {
        // Handle error
        console.log(error);
      });
  };

  return (
    <>
      <Header />
      <div className="pt-12 lg:pt-24"></div>
      <HeroProfile />
      <div className="bg-back-profile md:min-h-screen">
        <div className="max-w-1883px mx-auto px-6">
          <div className=" pt-10 pd:mt-0 flex flex-col md:grid  grid-rows-2 md:grid-cols-8 md:gap-10">
            <div className=" -mt-32 md:mt-0 md:col-span-3 lg:col-span-2 ">
              <AvatarProfile />
            </div>

            <div className=" md:-ml-5 md:col-span-5 lg:col-span-6  pb-10">
              <div className="">
                <div className="flex ">
                  <div className="mt-5 md:mt-0  w-full">
                    <form onSubmit={handleSubmit}>
                      <div className="shadow overflow-hidden rounded-xl">
                        <div className="px-4 py-5  sm:p-6 sm:pt-0">
                          <h2 className="pb-4">Profile Edit</h2>
                          <div className="grid grid-cols-6 gap-6 text-white">
                            {/* <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="email"
                                className="block text-sm font-medium text-white"
                              >
                                Email
                              </label>
                              <input
                                type="text"
                                name="email"
                                id="email"
                                value={settings.email}
                                onChange={handleChange}
                                autoComplete="email"
                                className=" px-2 mt-1  focus:border-gray-400 block w-full bg-gray-900 py-2 shadow-sm sm:text-base border-2 border-gray-800 rounded-md"
                              />
                            </div> */}

                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="username"
                                className="block text-sm font-medium text-white"
                              >
                                Username
                              </label>
                              <input
                                type="text"
                                name="username"
                                id="username"
                                autoComplete="username"
                                value={settings.username}
                                onChange={handleChange}
                                className=" px-2 mt-1  focus:border-gray-400 block w-full bg-gray-900 py-2 shadow-sm sm:text-base border-2 border-gray-800 rounded-md"
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="instagram"
                                className="block text-sm font-medium text-white"
                              >
                                Instagram
                              </label>
                              <input
                                type="text"
                                name="instagram"
                                id="instagram"
                                autoComplete="instagram"
                                value={settings.instagram}
                                onChange={handleChange}
                                className=" px-2 mt-1  focus:border-gray-400 block w-full bg-gray-900 py-2 shadow-sm sm:text-base border-2 border-gray-800 rounded-md"
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="facebook"
                                className="block text-sm font-medium text-white"
                              >
                                Facebook
                              </label>
                              <input
                                type="text"
                                name="facebook"
                                id="facebook"
                                autoComplete="facebook"
                                value={settings.facebook}
                                onChange={handleChange}
                                className=" px-2 mt-1  focus:border-gray-400 block w-full bg-gray-900 py-2 shadow-sm sm:text-base border-2 border-gray-800 rounded-md"
                              />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="linkedin"
                                className="block text-sm font-medium text-white"
                              >
                                Linkedin
                              </label>
                              <input
                                type="text"
                                name="linkedin"
                                id="linkedin"
                                value={settings.linkedin}
                                onChange={handleChange}
                                autoComplete="linkedin"
                                className=" px-2 mt-1  focus:border-gray-400 block w-full bg-gray-900 py-2 shadow-sm sm:text-base border-2 border-gray-800 rounded-md"
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="twitter"
                                className="block text-sm font-medium text-white"
                              >
                                Twitter
                              </label>
                              <input
                                type="text"
                                name="twitter"
                                id="twitter"
                                value={settings.twitter}
                                onChange={handleChange}
                                autoComplete="twitter"
                                className=" px-2 mt-1  focus:border-gray-400 block w-full bg-gray-900 py-2 shadow-sm sm:text-base border-2 border-gray-800 rounded-md"
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="walletAddress"
                                className="block text-sm font-medium text-white"
                              >
                                Wallet Address
                              </label>
                              <input
                                type="text"
                                name="walletAddress"
                                id="walletAddress"
                                value={settings.walletAddress}
                                onChange={handleChange}
                                autoComplete="walletAddress"
                                className=" px-2 mt-1  focus:border-gray-400 block w-full bg-gray-900 py-2 shadow-sm sm:text-base border-2 border-gray-800 rounded-md"
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="firstName"
                                className="block text-sm font-medium text-white"
                              >
                                First name
                              </label>
                              <input
                                type="text"
                                name="firstName"
                                id="firstName"
                                value={settings.firstName}
                                onChange={handleChange}
                                autoComplete="firstName"
                                className=" px-2 mt-1  focus:border-gray-400 block w-full bg-gray-900 py-2 shadow-sm sm:text-base border-2 border-gray-800 rounded-md"
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="lastName"
                                className="block text-sm font-medium text-white"
                              >
                                Last name
                              </label>
                              <input
                                type="text"
                                name="lastName"
                                id="lastName"
                                value={settings.lastName}
                                onChange={handleChange}
                                autoComplete="lastName"
                                className=" px-2 mt-1  focus:border-gray-400 block w-full bg-gray-900 py-2 shadow-sm sm:text-base border-2 border-gray-800 rounded-md"
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="phone"
                                className="block text-sm font-medium text-white"
                              >
                                Phone
                              </label>
                              <input
                                type="text"
                                name="phone"
                                id="phone"
                                value={settings.phone}
                                onChange={handleChange}
                                autoComplete="phone"
                                className=" px-2 mt-1  focus:border-gray-400 block w-full bg-gray-900 py-2 shadow-sm sm:text-base border-2 border-gray-800 rounded-md"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="px-4 py-3  flex sm:px-6 justify-center">
                          <button
                            type="submit"
                            className=" block whitespace-nowrap inline-flex items-center justify-center px-10 xl:px-20 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-button hover:bg-blue-b-h"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default ProfileEdit;
