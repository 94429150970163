import React from "react";
import { Link } from "react-router-dom";
const buttons = [
  { name: "LEX", href: "/profile/lex" },
  { name: "IMPERIUM", href: "/profile/imperium" },
  { name: "NFT", href: "/profile/nft" },
  { name: "OVERVIEW", href: "/dashboard" },
];
const ButtonsContent = () => {
  return (
    <div className=" sm:-ml-7 2xl:-ml-10 butoane  grid grid-cols-2 sm:grid-cols-4 justify-between  gap-2 sm:gap-0 ">
      {buttons.map((button, index) => (
        <div className=" sm:px-2 2xl:px-10 col-span-1">
          <Link
            key={index}
            to={button.href}
            className="bg-c-profile flex justify-center py-3 hover:bg-black-b-hover rounded-lg font-medium text-gray-500 duration-300"
          >
            {button.name}
          </Link>
        </div>
      ))}
    </div>
  );
};
export default ButtonsContent;
