import React, { useState } from "react";
import soon from "../../assets/soon.png";
import image1 from "../../assets/video player.png";
import image2 from "../../assets/video-player-2.png";
const videos = [
	{
		link: "https://www.youtube.com/embed/sLG820q6bJg?autoplay=1",
		image: image2
	}
];
const VideoExplainersModal = () => {
	const [open, setOpen] = useState(false);

	return (
		<section className="container-custom " id="video-explainer">
			<div className="rounded-3xl ">
				<h1 className="text-center text-4xl lg:text-7xl pb-5 2 lg:pb-10">
					Video Explainers
				</h1>
				<p className="text-white font-bold text-sm sm:text-lg pb-5">
					In order to better explain Imperium - The Crypto Index and its
					expanded ecosystem, we’ve created an ongoing series of video explainer
					videos, that will answer all of your questions and make everything
					crystal-clear.
				</p>
				{open != false ? (
					<div>
						<div
							className="overflow-hidden fixed z-110 w-full h-screen bg-gray-500 bg-opacity-50 left-0 top-0"
							onClick={() => {
								setOpen(false);
							}}
						>
							<div className="container-custom-video h-full ">
								<div className="video-container  top-1/2  left-1/2 transform -translate-x-1/2 -translate-y-1/2  relative">
									<svg
										onClick={() => {
											setOpen(false);
										}}
										xmlns="http://www.w3.org/2000/svg"
										width="25"
										height="25"
										fill="white"
										className="absolute right-5 -top-7 lg:top-0 lg:right-12 z-40 cursor-pointer"
										viewBox="0 0 16 16"
									>
										<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
										<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
									</svg>
									<iframe
										className="px-5 lg:px-32"
										src={videos[open - 1].link}
										frameborder="0"
										allow="accelerometer; autoplay; fullscreen; encrypted-media; gyroscope;"
										allowfullscreen
									></iframe>
								</div>
							</div>
						</div>
					</div>
				) : (
					""
				)}
				<div className=" pb-20  flex flex-wrap justify-center flex-row gap-10 sm:gap-0">
					{videos.map((video, index) => (
						<div
							className=" flex   w-full h-full  lg:w-1/2  sm:p-2 "
							key={index + 1}
						>
							<div
								onClick={() => {
									setOpen(index + 1);
								}}
								className="flex w-full h-full  sm:p-2 relative modal-video"
							>
								{/* <iframe
								className="w-full rounded-xl h-52 sm:h-96"
								src="https://www.youtube.com/embed/987izkjQykg"
							></iframe> */}
								<img src={video.image} className="blur-image object-cover" />
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="60"
									height="60"
									className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
									fill="#00D7FB"
									viewBox="0 0 16 16"
								>
									<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
								</svg>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};
export default VideoExplainersModal;
