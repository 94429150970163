import React from "react";
import { Footer, ScrollTopButton } from "../../Components";
import Header from "../../Components/Header/Header";
import Banner from "../../Components/Banner/Banner";

const ReferenceIndex = () => {
	return (
		<>
			<Header />
			<div className="m:pt-2 pt-24">
				<div className="container-custom pb-96">
					<h1 className="text-center text-4xl lg:text-7xl pb-5">
						IMΣ Coming soon...
					</h1>
					<p className="text-center text-lg lg:text-xl pb-5 text-white">
						Detailed analytics data on the index in the form of graphs, charts,
						and tables
					</p>
					<Banner />
				</div>
			</div>
			<ScrollTopButton />
			<Footer />
		</>
	);
};

export default ReferenceIndex;
