import axios from "axios";
import { auth } from ".";

import { HOST, PORT, VERSION } from "../Utils/CONSTANTS";

export const createUser = async (token, user) => {
	const { email, uid, metadata } = user._delegate;
	try {
		const res = await axios.post(
			`${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}${process.env.REACT_APP_VERSION}/users/createuser`,
			{
				user: { email, uid, metadata }
			}
		);
		const response = res.data;
		return response;
	} catch (err) {
		console.log(err);
	}
};

export const getUser = async (token, user) => {
	try {
		const res = await axios.get(
			`${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}${process.env.REACT_APP_VERSION}/users/getuser`,
			{
				headers: {
					id_token: token
				}
			}
		);
		const response = res.data.user;
		return response;
	} catch (err) {
		if (err.response.status === 404) {
			return -1;
			// createUser(token, user).then((response) => {
			//   console.log(response.user);
			//   return response.user;
			// });
		}
	}
};

// export const resetPassword = (email) => {
//   auth
//     .auth()
//     .sendPasswordResetEmail(email)
//     .then(() => {
//       // Password reset email sent!
//       // ..
//     })
//     .catch((error) => {
//       var errorCode = error.code;
//       var errorMessage = error.message;
//       // ..
//     });
// };
