import React from 'react';
import og from "../../assets/og.jpeg";
import MetaTags from "react-meta-tags";


const SEO = ({title, description}) => {
    return (
        <MetaTags>
        <title>{title}</title>
        <meta
          name="description"
          content={description}
        />
        <meta property="og:title" content={title} />
        <meta property="og:image" content={og} />
      </MetaTags>
    )
}

export default SEO;