import { Footer, ScrollTopButton } from "../../Components";
import Header from "../../Components/Header/Header";
import VideoExplainersModal from "../../Components/VideoExplainersModal/VideoEXplainersModal";
import howitwork from "../../assets/web-how-it-works1.png";
import howitworkmobile from "../../assets/mobile-how-it-works.png";
const HowItWorks = () => {
  return (
    <>
      <Header />
      <div id="how-it-work"></div>
      <div className="pt-24">
        <div className="container-custom">
          <h1 className="text-center text-4xl lg:text-7xl pb-5">
            How It Works
          </h1>
          <img src={howitwork} className=" hidden sm:block" />
          <img src={howitworkmobile} className="sm:hidden" />
        </div>
      </div>
      <VideoExplainersModal />
      <ScrollTopButton />
      <Footer />
    </>
  );
};

export default HowItWorks;
