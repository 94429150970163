import { HOST, PORT, VERSION } from "../Utils/CONSTANTS";
import axios from "axios";
const CoinGecko = require("coingecko-api");

//2. Initiate the CoinGecko API Client
const CoinGeckoClient = new CoinGecko();

export const getTopCoinsData = async () => {
	try {
		const data = await CoinGeckoClient.coins.markets({
			vs_currency: "usd",
			order: "market_cap_desc",
			per_page: 10,
			page: 1,
			sparkline: false,
			price_change_percentage: "24h"
		});
		// console.log(data);
		return data;
	} catch (e) {
		console.log(e);
	}
};

export const sendMail = async data => {
	// console.log(data);
	try {
		const res = await axios.post(
			`${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/api/v1/email/sendlead`,
			data
		);
		// console.log(res);
		const response = res.data;
		// console.log(response);
		return response;
	} catch (err) {
		console.log(err);
	}
};

export const getMarketCap = async () => {
	// console.log(data);
	try {
		const res = await axios.get(
			`${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/api/v1/users/getmarketcap`
		);
		// console.log(res);
		const response = res.data;
		// console.log(response);
		return response;
	} catch (err) {
		console.log(err);
	}
};

export const getTopRefactoring = async () => {
	// console.log(data);
	try {
		const res = await axios.get(
			`${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/api/v1/users/gettop`
		);
		// console.log(res);
		const response = res.data;
		// console.log(response);
		return response;
	} catch (err) {
		console.log(err);
	}
};

export const getCheckOutLinkApi = async (
	amount,
	tier,
	email,
	uid,
	paymentType
) => {
	if (paymentType == "card") {
		var payload = {
			link: {
				id: `${uid}`,
				email: `${email}`
			},
			user: {
				email: `${email}`
			},
			payment: {
				operation: "buy_with_fiat",
				symbol: `LEX_T${tier}`,
				amount: `${amount}`,
				secondSymbol: "EUR",
				request_id: Date.now() + "" + uid,
				method: "card",
				attempts: "3"
			},
			redirect_url: "https://imperium-privatesale.netlify.app/",
			expire: 1670060334
		};
	}

	if (paymentType == "bank") {
		var payload = {
			link: {
				id: `${uid}`,
				email: `${email}`
			},
			user: {
				email: `${email}`
			},
			payment: {
				operation: "buy_with_fiat",
				symbol: `LEX_T${tier}`,
				amount: `${amount}`,
				secondSymbol: "EUR",
				request_id: Date.now() + "" + uid,
				method: "bank",
				attempts: "3"
			},
			redirect_url: "https://imperium-privatesale.netlify.app/",
			expire: 1670060334
		};
	}

	if (paymentType == "crypto") {
		var payload = {
			link: {
				id: `${uid}`,
				email: `${email}`
			},
			user: {
				email: `${email}`
			},
			payment: {
				operation: "buy_with_crypto",
				symbol: `LEX_T${tier}`,
				amount: `${amount}`,
				secondSymbol: "USDT",
				request_id: Date.now() + "" + uid,
				attempts: "3"
			},
			redirect_url: "https://imperium-privatesale.netlify.app/",
			expire: 1670060334
		};
	}

	try {
		const res = await axios.get(
			`${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/api/v1/tokens/getcheckoutlinks`,
			{
				params: {
					payload
				}
			}
		);
		const response = res.data;
		// window.open(response.link, '_blank');
		window.location.replace(response.link);
		// const a = document.createElement("a");
		// a.setAttribute('href', response.link);
		// a.setAttribute('target', '_blank');
		// a.click();
		console.log(response);
		return response;
	} catch (err) {
		console.log(err);
	}
};

export const getPackages = async () => {
	// console.log(data);
	try {
		const res = await axios.get(
			`${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/api/v1/tokens/getPackages`
		);
		// console.log(res);
		const response = res.data;
		// console.log(response);
		return response;
	} catch (err) {
		console.log(err);
	}
};

export const getUserTokens = async token => {
	try {
		const res = await axios.get(
			`${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/api/v1/tokens/gettokens`,
			{
				headers: {
					id_token: token
				}
			}
		);
		const response = res.data;
		console.log(response);
		return response;
	} catch (err) {
		if (err.response.status === 404) {
			return -1;
		}
	}
};

export const getManualUserTokens = async token => {
	try {
		const res = await axios.get(
			`${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/api/v1/tokens/getmanualtokens`,
			{
				headers: {
					id_token: token
				}
			}
		);
		const response = res.data;
		console.log(response);
		return response;
	} catch (err) {
		if (err.response.status === 404) {
			return -1;
		}
	}
};

export const updateUser = async (token, settings) => {
	try {
		const res = await axios.patch(
			`${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}${process.env.REACT_APP_VERSION}/users/updateuser`,
			settings,
			{
				headers: {
					id_token: token
				}
			}
		);
		const response = res.data.user;
		return response;
	} catch (err) {
		if (err.response.status === 404) {
			return -1;
		}
	}
};

export const updateUserProfileImage = async (token, settings) => {
	try {
		const res = await axios.patch(
			`${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}${process.env.REACT_APP_VERSION}/users/updateuserprofileimage`,
			settings,
			{
				headers: {
					id_token: token
				}
			}
		);
		const response = res.data.user;
		return response;
	} catch (err) {
		if (err.response.status === 404) {
			return -1;
		}
	}
};

export const sendMailNewsletter = async data => {
	// console.log(data);
	try {
		const res = await axios.post(
			`${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/api/v1/email/subscribenewsletter`,
			data
		);
		// console.log(res);
		const response = res.data;
		// console.log(response);
		return response;
	} catch (err) {
		console.log(err);
	}
};
