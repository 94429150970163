/* eslint-disable react/prop-types */
import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../Api";
import { createUser, getUser } from "../Api/auth.api";
import { LoadingContext } from "./LoadingProvider";

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [user, setUser] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useContext(LoadingContext);

  useEffect(() => {
    auth.auth().onAuthStateChanged((userData) => {
      // setUser(userData);
      if (userData) {
        auth
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then(async function (idToken) {
            getUser(idToken, userData).then((response) => {
              if(response != -1) {
                setUser({ ...userData, user: response });
                setLoading(false);
              } else {
                createUser(idToken, userData).then((response) => {
                  setUser({ ...userData, user: response.user });
                  setLoading(false);
                });
              }
            });
          })
          .catch(function (error) {
            // Handle error
            console.log(error);
          });
      } else {
        setLoading(false);
      }
    });
  }, []);



  return (
    <UserContext.Provider value={[user, setUser]}>
      {props.children}
    </UserContext.Provider>
  );
}
