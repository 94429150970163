import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import logo from "../../assets/logonou_footer.png";
import discord from "../../assets/social/discord.png";
import facebook_dark from "../../assets/social/facebook_dark.png";
import github from "../../assets/social/github.png";
import in_dark from "../../assets/social/in_dark.png";
import instagram from "../../assets/social/instagram.png";
import medium from "../../assets/social/medium.png";
import reddit from "../../assets/social/reddit.png";
import twitter02_dark from "../../assets/social/twitter02_dark.png";
import youtube from "../../assets/social/youtube.png";
import wplogo from "../../assets/social/wplogo.png";
import ro_telegram from "../../assets/social/ro_telegram.png";
import en_telegram from "../../assets/social/telegram.png";
import tiktok from "../../assets/social/tiktok.png";
import { sendMailNewsletter } from "../../Api/Api";
import { toast } from "react-toastify";

const menu = [
	{
		name: "Home",
		href: "/",
		submenu: [
			{ name: "About us", href: "/#about" },
			{ name: "Imperium", href: "/#imperium" },
			{ name: "Presentation", href: "/#presentation" },
			{ name: "Roadmap", href: "/#roadmap" },
			{ name: "Team", href: "/#team" }
		]
	},
	{
		name: "Lex",
		href: "/lex",
		submenu: [
			{ name: "Token", href: "/lex#token" },
			{ name: "Tokenomics", href: "/lex#tokenomics" },
			{ name: "Litepaper", href: "/lex#litepaper" },
			{ name: "Whitepaper ", href: "/lex#whitepaper" }
		]
	},
	{
		name: "MVP SIMULATOR",
		href: "/mvp",
		submenu: [
			{ name: "Presentation", href: "/mvp/#presentation" },
			{ name: "Investment Simulation", href: "/mvp/#investment" }
		]
	},
	{
		name: "How It Works",
		href: "/how-it-works",
		submenu: [
			{ name: "How It Works", href: "/how-it-works/#how-it-work" },
			{ name: "Video Explainers", href: "/how-it-works/#video-explainer" }
		]
	}
];
const Footer = () => {
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
			/* you can also use 'auto' behaviour
         in place of 'smooth' */
		});
	};

	const handleOnSubmit = async e => {
		e.preventDefault();
		if (email != "") {
			const response = await sendMailNewsletter({
				email
			});
			console.log(response.msg);
			if (response.msg == "success") {
				const onSend = () => {
					setTimeout(() => {
						setSend("Subscribe!");
					}, 10);
					setTimeout(() => {
						setSend("Subscribe");
					}, 3000);
					return () => send;
				};
				onSend();
				toast.success("You have subscribed to the newsletter!", {
					position: "bottom-center",
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					progress: undefined
				});
				cleanFields();
			} else {
				toast.error("You may have already subscribed.", {
					position: "bottom-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					progress: undefined
				});
			}
		} else {
			toast.error("Invalid email!", {
				position: "bottom-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				draggable: true,
				progress: undefined
			});
		}
	};
	const [email, setMail] = useState("");
	const [send, setSend] = useState("Subscribe");
	const cleanFields = () => {
		setMail("");
	};
	return (
		<>
			<div className=" py-16 sm:py-24">
				<div className="relative sm:py-16">
					<div aria-hidden="true" className="hidden sm:block"></div>
					<div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
						<div className="relative overflow-hidden rounded-2xl bg-gray-800 px-6 py-10 shadow-xl sm:px-12 sm:py-20">
							<div
								aria-hidden="true"
								className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0"
							>
								<svg
									className="absolute inset-0 h-full w-full"
									preserveAspectRatio="xMidYMid slice"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 1463 360"
								>
									<path
										className="text-gray-800 text-opacity-40"
										fill="currentColor"
										d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
									/>
									<path
										className="text-gray-800 text-opacity-40"
										fill="currentColor"
										d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
									/>
								</svg>
							</div>
							<div className="relative">
								<div className="sm:text-center">
									<h2 className="text-3xl font-bold tracking-tight font-lato-css text-white sm:text-4xl text-center">
										Stay in the know!
									</h2>
									<p className="mx-auto mt-6 max-w-2xl  text-lg text-white font-lato-css text-center">
										Subscribe to our newsletter and receive weekly news and
										updates about Imperium.
									</p>
								</div>
								<form
									onSubmit={handleOnSubmit}
									className="mt-12 sm:mx-auto sm:flex sm:max-w-lg mb-2"
								>
									<div className="min-w-0 flex-1">
										<label htmlFor="cta-email" className="sr-only">
											Email address
										</label>
										<input
											required
											id="cta-email"
											type="email"
											className="block w-full rounded-md border border-transparent px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none"
											placeholder="Enter your email"
											value={email}
											onChange={e => setMail(e.target.value)}
										/>
									</div>
									<div className="mt-4 sm:mt-0 sm:ml-3">
										<button
											type="submit"
											className="block w-full rounded-md border border-transparent bg-blue-button px-5 py-3 text-base font-medium text-white shadow hover:bg-blue-b-h focus:outline-none  sm:px-10"
										>
											{send}
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
			<footer className=" py-12 px-8 2xl:px-32 flex flex-col xl:flex-row justify-between gap-5">
				<div className="mb-5 sm:mb-20 flex flex-col  gap-5 xl:mr-10 sm:gap-0 w-56  self-center xl:self-start">
					<img
						className="cursor-pointer w-34"
						src={logo}
						onClick={scrollToTop}
					/>
				</div>
				<div className="social-media flex-start lg:justify-center xl:justify-start w-full">
					<div className="font-bold mb-2 uppercase text-white-f text-xl">
						Social Media
					</div>
					<div className="flex flex-wrap items-center mb-10 gap-2">
						<a href="https://twitter.com/imperiumindex" target="_blank">
							<img src={twitter02_dark} />
						</a>
						<a href="https://discord.com/invite/9hZnWDcHE8" target="_blank">
							<img src={discord} />
						</a>

						<a href="https://t.me/ImperiumIndex" className=" " target="_blank">
							<img className="opacity-80" src={en_telegram} />
							{/* <p className="telegram-font">RO</p> */}
						</a>

						<a href="https://medium.com/@imperiumindex" target="_blank">
							<img src={medium} />
						</a>
						<a
							href="https://www.youtube.com/channel/UCKb-WjNrJw3VrNeTQXDKYtw"
							target="_blank"
						>
							{" "}
							<img src={youtube} />
						</a>
						<a href="https://www.reddit.com/user/IMPERIUMINDEX" target="_blank">
							<img src={reddit} />
						</a>
						<a
							href="https://www.linkedin.com/company/imperium-index/"
							target="_blank"
						>
							{" "}
							<img src={in_dark} />
						</a>
						<a
							href="https://www.tiktok.com/@imperiumindex"
							className=" "
							target="_blank"
						>
							<img className="opacity-80" src={tiktok} />
							{/* <p className="telegram-font">RO</p> */}
						</a>
						<a href="https://www.instagram.com/imperiumindex/" target="_blank">
							{" "}
							<img src={instagram} />
						</a>

						<a href="https://www.facebook.com/IMPERIUMINDEX" target="_blank">
							{" "}
							<img src={facebook_dark} />
						</a>

						<a href="https://wa.me/40735102050" target="_blank">
							{" "}
							<img src={wplogo} />
						</a>
						<a href="https://github.com/imperiumindex" target="_blank">
							{" "}
							<img src={github} />
						</a>
					</div>
					<div className=" mb-2 uppercase text-white-f text-xl font-bold">
						Contact
					</div>
					<a
						href="mailto:contact@imperium-lex.com"
						className="text-white-f hover:underline mb-10 block"
					>
						contact@imperium-lex.com
					</a>
				</div>
				<div className="flex 	w-full flex-col md:flex-row gap-5 sm:gap-10 justify-between">
					{menu.map((item, index) => (
						<div>
							<Link
								className="text-white-f text-xl font-bold hover:text-color-text-f mb-2 sm:mb-5 inline-block uppercase whitespace-nowrap"
								onClick={scrollToTop}
								key={index}
								to={item.href}
							>
								{item.name}
							</Link>
							{item.submenu ? (
								<div className="flex flex-col">
									{item.submenu.map((itm, indx) => (
										<Link
											onClick={itm.top ? scrollToTop : ""}
											key={indx}
											to={itm.href}
											className="mb-1 sm:mb-2 inline-block"
										>
											<div className="">
												<p className="text-lg font-medium text-color-text-f hover:text-white whitespace-nowrap">
													{itm.name}
												</p>
											</div>
										</Link>
									))}
								</div>
							) : (
								""
							)}
						</div>
					))}
				</div>
			</footer>
			<div className="px-8 2xl:px-32 flex flex-col-reverse gap-4 xl:flex-row justify-between py-5">
				<p className="text-center text-color-text-f text-sm font-bold">
					Copyright &copy; 2022 IMPERIUM. All rights reserved
				</p>
				<div className="flex gap-3 justify-center flex-wrap">
					<Link
						onClick={scrollToTop}
						to="/legal/aml-disclaimer"
						className="text-center text-sm text-color-text-f hover:text-white"
					>
						AML Disclaimer
					</Link>
					<Link
						onClick={scrollToTop}
						to="/legal/risk-disclaimer"
						className="text-center text-sm text-color-text-f hover:text-white"
					>
						Risk Disclaimer
					</Link>
					<Link
						onClick={scrollToTop}
						to="/legal/complaints-handling-procedure"
						className="text-center text-sm text-color-text-f hover:text-white"
					>
						Compliants Handling Procedure
					</Link>
					<Link
						onClick={scrollToTop}
						to="/legal/terms-and-conditions"
						className="text-center text-sm text-color-text-f hover:text-white"
					>
						Terms and conditions
					</Link>
					<Link
						onClick={scrollToTop}
						to="/legal/cookie-policy"
						className="text-center text-sm text-color-text-f hover:text-white"
					>
						Cookie Policy
					</Link>
					<Link
						onClick={scrollToTop}
						to="/legal/privacy-policy"
						className="text-center text-sm text-color-text-f hover:text-white"
					>
						Privacy Policy
					</Link>
				</div>
			</div>
		</>
	);
};

export default Footer;
