import Header from "../../Components/Header/Header";
import { Footer, ScrollTopButton, SEO } from "../../Components";
import { Slider4 } from "../Sliders";
import simulator from "../../assets/simulator.png";
import imperium from "../../assets/imagine-generala-imperium.png";
import Line from "../../Components/Line/Line";
import { Link } from "react-router-dom";
import { Cta } from "../../Components";

const Mvp = () => {
	return (
		<>
			<Header />
			<div className="m:pt-2 pt-24">
				<Slider4
					anchor="presentation"
					title="Presentation"
					subtitle="SLR Algorithm"
					poza={imperium}
					paragraph={
						<p className="mb-8 text-white text-sm">
							The Imperium Index Funds will be balanced by our patent-pending
							SLR Algorithm, which lowers lower investment risks and optimizes
							returns by automatically performing all the trades. <br />
							<br />
							It does Market Cap checks every few seconds & automatic
							rebalancing every few minutes. This makes Imperium the fastest
							Rebalanced Crypto Index in the world, by following the exact
							crypto market capitalization in real time. <br />
							<br />
							The SLR Algorithm watches key parameters of multiple crypto
							marketplaces. It analyzes the difference between our portfolio and
							the current market state. If the discrepancy is more than 0.5%, a
							corrective action is taken, which always involves selling one
							asset and buying another.
							<br />
							<br />
							SLR uses optimization algorithms for storing and trading the
							assets, so that all transactions are executed as efficiently as
							possible in the current market.
							<br />
							<br />
							For security reasons, Imperium chooses to place the SLR Algorithm
							on several servers around the world to always have it in working
							order.
						</p>
					}
					position="right"
					pdf={""}
					titlepdf="BUY LEX"
				/>
				{/* <Slider4
          anchor="investment"
          title="Imperium Index Simulator"
          subtitle="Use our Index Simulator to compare the proffits of your hypothetical investment, based on the index of your choosing."
          poza={simulator}
          position="left"
          pdf={'https://mvp.imperium-lex.com/'}
          titlepdf="SIMULATE YOUR INVESTMENT"
        /> */}
				<div className="container-custom slider-4" id="investment">
					<div className="grid-2-custom">
						<div className={`zona-text order-1`}>
							<div>
								<h2 className="uppercase text-4xl pb-2">
									Imperium Index Simulator
								</h2>
								<h3 className="text-2xl">
									Use our Index Simulator to compare the profits of your
									hypothetical investment, based on the index of your choosing.
								</h3>
								<Line className={"my-4"} />
								<p className="mb-8 text-white text-sm">
									All you have to do is to define your window of time,
									investment size and your preferred index – then you can run
									your simulation and analyze the returns you’d get from
									different investment strategies.
									<br />
									<br />
									Your simulation will also be generating a detailed report
									based on historical data.
									<br />
									<br />
									Explore the Imperium Simulator in detail, by running as many
									simulations as you need to make the optimal investment
									decision.
									<br />
									<br />
								</p>

								<a href="https://mvp.imperium-lex.com/" target="_BLANK">
									<Cta
										text="SIMULATE YOUR INVESTMENT"
										// href={litepaperpdf}
										target="_BLANK"
										className="text-lg block lg:inline"
									/>
								</a>
							</div>
						</div>
						<div className="flex justify-center self-center">
							<img src={simulator} loading="lazy" />
						</div>
					</div>
				</div>
			</div>
			<ScrollTopButton />
			<Footer />
		</>
	);
};

export default Mvp;
