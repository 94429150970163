import litepaper from '../../assets/litepaper.png';
import Cta from '../Cta/Cta';
import litepaperpdf from '../../assets/Litepaper 2.0.pdf';

import token from '../../assets/simulator.png';
import { Link } from 'react-router-dom';
const WhatIsImperium = ({ anchor }) => {
  return (
    <section className="" id={`${anchor}`}>
      <div className="container-custom ">
        <div className="grid lg:grid-cols-2 gap-10 lg:gap-5 items-center ">
          <div className="order-1 md:order-2 lg:order-2">
            <h4 className="uppercase text-4xl text-white pb-4">
              THE INDEX METHOD
            </h4>

            <p className="mb-8 text-white text-sm">
              Until recently, investing was reserved exclusively for the rich
              and connected. But technology and the markets started providing us
              users with many investment tools that are finally making the
              financial space accessible for everyone. <br />
              <br />
              Retail investors can use apps such as Robin Hood or Vanguard to
              invest in market indexes, gaining reliable returns on their
              investment without having to become expert investors. <br />
              <br />
              This is exactly what the Imperium Index is offering crypto
              investors. A way to always be invested in the top crypto projects
              that the markets have deemed most reliable and have the most
              upside potential.
              <br />
              <br />
              Web 3.0 will change the world by making many institutions
              obsolete. Crypto uses the blockchain & smart contract technology
              to directly connect market participants and in the process make
              arbiters and middlemen obsolete.
              <br />
              <br />
              This is why we have absolute certainty that the market will bounce
              back stronger after every crash and that has been true so far for
              both the stock market and the crypto market as well.
              <br />
              <br />
              The Imperium Crypto Index is a tool for regular users to invest in
              this revolutionary field and trust the collective research and
              judgment of almost 4% of the global population!
            </p>
            <Link to="/litepaper" target="_BLANK">
              <Cta
                text="Explore our concept & detailed solutions"
                // href={litepaperpdf}
                target="_BLANK"
                className="text-xs"
              />
            </Link>
          </div>

          <div className="flex justify-center self-center order-1 lg:order-2">
            <img src={token} loading="lazy" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatIsImperium;
