import React from "react";
import { Footer } from "../../Components";
import Header from "../../Components/Header/Header";
import AvatarProfile from "../Profile/AvatarProfile";

import ButtonsContent from "../Profile/ButtonsContent";
import HeroProfile from "../Profile/HeroProfile";
import Overview from "../Profile/Overview";
const items = [
  { number: "#890", name: "Coming soon" },
  { number: "#809", name: "Coming soon" },
  { number: "#987", name: "Coming soon" },
  { number: "#867", name: "Coming soon" },
  { number: "#678", name: "Coming soon" },
];
const ProfileNFT = () => {
  return (
    <>
      <Header />
      <div className="pt-12 lg:pt-24"></div>
      <HeroProfile />
      <div className="bg-back-profile md:min-h-screen">
        <div className="max-w-1883px mx-auto px-6">
          <div className=" pt-10 pd:mt-0 flex flex-col md:grid  grid-rows-2 md:grid-cols-8 md:gap-10">
            <div className=" -mt-32 md:mt-0 md:col-span-3 lg:col-span-2 ">
              <AvatarProfile />
            </div>
            <div className=" col-span-5 lg:col-span-6 pb-10 pt-10 md:pt-0">
              <ButtonsContent />
              <div className="mr-5">
                <Overview items={items} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default ProfileNFT;
