import React from 'react';
import litepaperpdf from '../../assets/Litepaper 2.0.pdf';
import { Footer } from '../../Components';
import Header from '../../Components/Header/Header';
const LitePaper = () => {
  return (
    <>
      <iframe width="100%" className="h-screen" src={litepaperpdf}></iframe>
    </>
  );
};

export default LitePaper;
