import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getStorage } from "firebase/storage";
const firebaseConfig = {
	apiKey: "AIzaSyDuoz-gfGeQtUmHb8f_3RjNhj93_ZDDfos",
	authDomain: "imperiumlex-d60a8.firebaseapp.com",
	projectId: "imperiumlex-d60a8",
	storageBucket: "imperiumlex-d60a8.appspot.com",
	messagingSenderId: "10694290072",
	appId: "1:10694290072:web:f829f97d1bc6f3e6e43eed"
};

export const app = firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();

// Google Provider

const provider = new firebase.auth.GoogleAuthProvider();

provider.setCustomParameters({ prompt: "select_account" });

export const signInWithGoogle = () => auth.signInWithPopup(provider);

// Facebook Provider

const facebook_provider = new firebase.auth.FacebookAuthProvider();

export const signInWithFacebook = () => auth.signInWithPopup(facebook_provider);

// Login simplu

export const logInWithEmailAndPassword = async ({ email, password }) => {
	console.log(email, password);
	try {
		await auth.signInWithEmailAndPassword(email, password);
	} catch (err) {
		console.error(err);
		toast.error(err.message, {
			position: toast.POSITION.BOTTOM_CENTER
		});
	}
};

export const registerWithEmailAndPassword = async ({ email, password }) => {
	try {
		const res = await auth.createUserWithEmailAndPassword(email, password);

		const user = res.user;
		console.log(user);
	} catch (err) {
		console.error(err);
		toast.error(err.message, {
			position: toast.POSITION.BOTTOM_CENTER
		});
	}
};

export const forgotPasswordWithEmail = async email => {
	try {
		const res = await auth.sendPasswordResetEmail(email).then(function () {
			toast.success("Please verify your email!!", {
				position: toast.POSITION.BOTTOM_CENTER
			});
		});
	} catch (err) {
		console.error(err);
		toast.error(err.message, {
			position: toast.POSITION.BOTTOM_CENTER
		});
	}
};

export const storage = getStorage(app);

export default firebase;
