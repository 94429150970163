import React from "react";
import { Footer, ScrollTopButton } from "../../Components";
import Header from "../../Components/Header/Header";
import WhatIsImperium from "../../Components/WhatIsImperium/WhatIsImperium";
import WhitePaper from "../Sliders/WhitePaper/WhitePaper";
import { Slider2 } from "../../Pages/Sliders";
import litepaperpdf from "../../assets/Litepaper Imperium 2.0.pdf";
const Imperium = () => {
	return (
		<>
			<Header />
			<section className=" lg:h-20"></section>
			<WhatIsImperium anchor="what-is-imperium" />

			<WhitePaper
				anchor="whitepaper"
				title="Whitepaper"
				subtitle=""
				link={litepaperpdf}
				paragraph={
					<p className="mb-8 text-white">
						Discover the inner workings of Imperium in detail, just as it was
						designed by our team of economic and crypto experts.
						<br />
						<br />
						Index funds have become the benchmark in measuring the success of
						investment strategies due to their undeniable and reliable gains.{" "}
						<br />
						<br />
						But the Imperium Index is powered by the LEX token, in a complex
						ecosystem designed to maximize your gains and hedge your
						investments.
						<br />
						<br />
						Read this document if you want to explore all the major themes from
						the Litepaper, at a more technical level.
					</p>
				}
			/>
			<Slider2
				anchor="imperium"
				title={"IMPERIUM"}
				subtitle={"Introducing the IMPERIUM INDEX"}
				paragraph={
					<>
						<p>
							Lorem Ipsum is simply dummy text of the printing and typesetting
							industry. Lorem Ipsum has been the industry's standard dummy text
							ever since the 1500s, when an unknown printer took a galley of
							type and scrambled it to make a type specimen book. It has
							survived not only five centuries, but also the leap into
							electronic typesetting, remaining essentially unchanged.
						</p>
						<br />
						<p>
							Lorem Ipsum is simply dummy text of the printing and typesetting
							industry. Lorem Ipsum has been the industry's standard dummy text
							ever since the 1500s, when an unknown printer took a galley of
							type and scrambled it to make a type specimen book. It has
							survived not only five centuries, but also the leap into
							electronic typesetting, remaining essentially unchanged.
						</p>
					</>
				}
			/>
			<ScrollTopButton />
			<Footer />
		</>
	);
};

export default Imperium;
