import React, { useContext, useState } from "react";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import { UserContext } from "../../Providers/UserProvider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../assets/logonou.png";
import {
	logInWithEmailAndPassword,
	signInWithFacebook,
	signInWithGoogle
} from "../../Api/firebaseConfig";
import btn_google from "../../assets/google-icon.svg";
const Login = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [user, setUser] = useContext(UserContext);

	const handleLogin = async event => {
		event.preventDefault();
		let result;

		if (email == "" || password == "") {
			console.log("empty");
		} else {
			result = await logInWithEmailAndPassword({ email, password });
		}
	};

	if (user) {
		return <Redirect to="/dashboard" />;
	}

	return (
		<>
			<div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
				<div className="max-w-lg w-full space-y-8  p-10 rounded-2xl">
					<div>
						<Link to="/">
							<img className="mx-auto w-40" src={logo} alt="Workflow" />
						</Link>
						<h2 className="mt-6 text-center ">Sign in</h2>
						<p className="mt-2 text-center text-sm text-gray-600">
							Or
							<Link
								to="/register"
								className=" text-lg text-blue-button hover:text-blue-b-h"
							>
								&nbsp;Register new account
							</Link>
						</p>
					</div>
					<form className="py-2" onSubmit={handleLogin}>
						<input type="hidden" name="remember" value="true" />
						<div className="rounded-md shadow-sm flex flex-col gap-2 text-white">
							<div>
								<label for="email-address" className="sr-only">
									Email address
								</label>
								<input
									id="email-address"
									name="email"
									type="email"
									autocomplete="email"
									required
									value={email}
									onChange={e => setEmail(e.target.value)}
									className=" px-2 mt-1  focus:border-gray-400 block w-full bg-gray-900 py-2 shadow-sm sm:text-base border-2 border-gray-800 rounded-md"
									placeholder="Email address"
								/>
							</div>
							<div>
								<label for="password" className="sr-only">
									Password
								</label>
								<input
									id="password"
									name="password"
									type="password"
									autocomplete="current-password"
									required
									value={password}
									onChange={e => setPassword(e.target.value)}
									className=" px-2 mt-1  focus:border-gray-400 block w-full bg-gray-900 py-2 shadow-sm sm:text-base border-2 border-gray-800 rounded-md"
									placeholder="Password"
								/>
							</div>
						</div>

						<div className="flex justify-center pt-4 flex-col">
							<button
								type="submit"
								className="block whitespace-nowrap inline-flex items-center justify-center px-3 xl:px-10 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-button hover:bg-blue-b-h"
							>
								SIGN IN
							</button>
							<div className="grid grid-cols-1 items-center gap-5">
								<div className="flex justify-center mt-2">
									<div
										className="bg-white hover:bg-gray-100 rounded-md  cursor-pointer flex justify-center items-center gap-3 w-full py-2 px-2"
										onClick={() => {
											signInWithGoogle();
										}}
									>
										<img src={btn_google} />
										<span className="text-sm flex gap-1">
											{" "}
											Sign In
											<span className="hidden sm:block"> With Google</span>
										</span>
									</div>
								</div>
								{/* <div className="flex justify-center mt-2">
									<div
										className="text-white rounded-md  bg-facebook-color hover:bg-facebook-hover py-2 px-2 cursor-pointer flex items-center justify-center gap-5 w-full"
										onClick={() => {
											signInWithFacebook();
										}}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="25"
											height="25"
											fill="currentColor"
											className="bi bi-facebook"
											viewBox="0 0 16 16"
										>
											<path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
										</svg>
										<span className="text-sm flex gap-1">
											{" "}
											Sign In{" "}
											<span className="hidden sm:block">With Facebook</span>
										</span>
									</div>
								</div> */}
							</div>
						</div>
					</form>
					<hr />
					<div className="flex flex-row justify-center items-center space-x-3">
						<Link
							to="/"
							className="block whitespace-nowrap inline-flex items-center justify-center px-3 xl:px-10 py-2  rounded-md shadow-sm text-base font-medium text-white "
						>
							GO HOME
						</Link>
						<Link
							to="/forgot-password"
							className="block whitespace-nowrap inline-flex items-center justify-center px-3 xl:px-10 py-2  rounded-md shadow-sm text-base font-medium text-white "
						>
							Forgot Password
						</Link>
					</div>
				</div>
			</div>
			<ToastContainer />
		</>
	);
};

export default Login;
