import React from "react";
import Header from "../../Components/Header/Header";
import { Footer, ScrollTopButton, SEO, VestingTabel } from "../../Components";
import { Slider3, Slider4, Slider8 } from "../Sliders";
import WhitePaper from "../Sliders/WhitePaper/WhitePaper";
import litepaperpdf from "../../assets/Litepaper Imperium 2.0.pdf";
import litepaper from "../../assets/litepaper.png";
import Line from "../../Components/Line/Line";
import token from "../../assets/lex-illustration.png";

const Lex = () => {
	return (
		<>
			<SEO
				title="IMPERIUM - THE CRYPTO INDEX"
				description="IMPERIUM is both a collection of indices that are tracking the top cryptocurrencies worldwide and a tool for investing in them according to each asset’s market cap."
			/>
			<Header />
			<div className="pt-24">
				{/* <Slider3 anchor="token" /> */}
				<div className="container-custom slider-3" id="token">
					<div className="grid-2-custom">
						<div>
							<img src={token} loading="lazy" />
						</div>
						<div className="zona-text">
							<h2 className="text-4xl">LEX Token - Your keys to the Empire</h2>
							<h3 className="text-2xl">
								LEX is the independent token that makes the Imperium ecosystem
								possible.
							</h3>
							<Line className={"my-4"} />
							<p className="text-white">
								Lex is the backbone of the Imperium ecosystem, and will serve
								several functions within, such as:
							</p>
							<br />
							<ul className="text-white">
								<li>
									🔹 It is necessary in order to whitelist any investment in
									Imperium. Please note that the set amount of LEX for
									whitelisting only needs to be present in the investor’s wallet
									for the platform’s buy-in and cash-out functions. It doesn’t
									need to be staked and it doesn’t get locked in any manner.
								</li>
								<br />
								<li>
									🔹 LEX is a deflationary token, that will grow in value as the
									supply gets slowly burned, but also as the space develops and
									more people will be using The Crypto Index.
								</li>
								<br />
								<li>
									🔹 Users will be, however, incentivized to hold LEX – in order
									to massively supplement their earnings and hedge their overall
									investment – and use it for Liquidity Providing and Staking.
								</li>
							</ul>
						</div>
					</div>
				</div>
				<Slider8 anchor="tokenomics" textlink="Read More" />
				{/* <VestingTabel /> */}
				<Slider4
					anchor="litepaper"
					title={"Litepaper"}
					subtitle={"Take a deep dive in the Imperium Ecosystem"}
					poza={litepaper}
					paragraph={
						<p className="mb-8 text-white">
							The crypto space is still in its early days. That means that it’s
							still an amazing time for investors to get returns that are
							unheard of in the traditional stock markets. <br />
							<br />
							For regular users, the knowledge required to be successful in both
							the Bull Markets and the Bear Markets requires total dedication
							and acts as a very demanding barrier to entry. <br />
							<br />
							According to our estimates, investing in crypto requires too much
							research and technical knowledge for over 90% of market
							participants. <br />
							<br />
							Even so, the kind of returns that this market offers has been very
							tempting for over 300 million people so far, almost 4% of the
							global population. Adoption will grow exponentially, as more
							projects are created, and as the crypto environment becomes more
							user-friendly. <br />
							<br />
							Imperium will be at the forefront of this paradigm shift,
							providing investors with a high-tech system to mitigate risk and
							maximize profits. <br />
							<br />
							Read our detailed approach to building The Crypto Index.
						</p>
					}
					position={"right"}
					pdf={litepaperpdf}
					titlepdf="Explore our concept & detailed solutions"
				/>
				<WhitePaper
					anchor="whitepaper"
					title="Whitepaper"
					subtitle=""
					link=""
					paragraph={
						<p className="mb-8 text-white">
							Discover the inner workings of Imperium in detail, just as it was
							designed by our team of economic and crypto experts.
							<br />
							<br />
							Index funds have become the benchmark in measuring the success of
							investment strategies due to their undeniable and reliable gains.
							<br />
							<br />
							But the Imperium Index is powered by the LEX token, in a complex
							ecosystem designed to maximize your gains and hedge your
							investments.
							<br />
							<br />
							Read this document if you want to explore all the major themes
							from the Litepaper, at a more technical level.
						</p>
					}
				/>
			</div>
			<ScrollTopButton />
			<Footer />
		</>
	);
};

export default Lex;
