import React, { useState } from "react";
import Line from "../../Components/Line/Line";
import image from "../../assets/video player.png";

const TheCryptoIndexSection = () => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<div className="container-custom">
				<div className="grid grid-cols-1 lg:grid-cols-2  gap-5 items-center">
					<div className="col-span-1 flex flex-col items-between ">
						<div className="sm:mb-10">
							<h2>Introducing IMPERIUM, #TheCryptoIndex</h2>
							<Line className={"my-4"} />
						</div>
						<div className="text-white max-w-4xl">
							Imperium’s purpose is to solve the problems of crypto investing,
							offering an easy-to-use investment tool that provides simplicity,
							security, ownership and permanent exposure to the top
							cryptocurrencies. <br />
							<br />
							Use our Index Simulator to compare the profits of your
							hypothetical investment, based on the index of your choosing.
						</div>
						<div className="flex items-start">
							<a
								className=" bg-blue-button text-sm mt-5 text-white hover:bg-blue-b-h rounded-lg px-3 py-2"
								href="https://mvp.imperium-lex.com/"
								target="_blank"
							>
								Simulate your investment
							</a>
						</div>
					</div>
					<div className="col-span-1 justify-center flex-row gap-10 sm:gap-0">
						{open ? (
							<div
								className="overflow-hidden fixed z-110 w-full h-screen bg-gray-500 bg-opacity-50 left-0 top-0"
								onClick={() => {
									setOpen(false);
								}}
							>
								<div className="container-custom-video h-full">
									<div className="video-container  top-1/2  left-1/2 transform -translate-x-1/2 -translate-y-1/2  relative">
										<svg
											onClick={() => {
												setOpen(false);
											}}
											xmlns="http://www.w3.org/2000/svg"
											width="25"
											height="25"
											fill="white"
											className="absolute right-5 -top-7 lg:top-0 lg:right-12 z-40 cursor-pointer"
											viewBox="0 0 16 16"
										>
											<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
											<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
										</svg>
										<iframe
											className="px-5 lg:px-32"
											src="https://www.youtube.com/embed/987izkjQykg?autoplay=1"
											frameborder="0"
											allow="accelerometer; fullscreen; autoplay; encrypted-media; gyroscope;"
											allowfullscreen
										></iframe>
									</div>
								</div>
							</div>
						) : (
							""
						)}
						<div
							onClick={() => {
								setOpen(true);
							}}
							className="flex w-full h-full  sm:p-2 relative modal-video"
						>
							{/* <iframe
								className="w-full rounded-xl h-52 sm:h-96"
								src="https://www.youtube.com/embed/987izkjQykg"
							></iframe> */}
							<img src={image} className="blur-image" />
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="60"
								height="60"
								className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
								fill="#00D7FB"
								viewBox="0 0 16 16"
							>
								<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
							</svg>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default TheCryptoIndexSection;
