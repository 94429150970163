// import './MainPage.css';
import React from "react";
import { BrowserRouter as Router, useHistory } from "react-router-dom";

import Modal from "../../../Components/Modal/Modal";
import token from "../../../assets/LEX-token.png";
import Line from "../../../Components/Line/Line";

const Slider3 = ({ anchor }) => {
	return (
		<div className="container-custom slider-3" id={`${anchor}`}>
			<div className="grid grid-2-custom">
				<div className="order-2 lg:order-1">
					<img src={token} loading="lazy" />
				</div>
				<div className="zona-text order-1 lg:order-2">
					<h2 className="text-4xl">LEX Token - Your keys to the Empire</h2>
					<h3 className="text-2xl">
						LEX is an independent token fueling the Imperium ecosystem.
					</h3>
					<Line className={"my-4"} />
					<p className="text-white">
						In order to be able to whitelist your investment in Imperium, you
						must own a set quantity of $LEX. After investing, you are issued an
						NFT that constitutes proof of ownership of the underlying assets.
						<br />
						<br />
						$LEX can be staked for extra rewards or even be sold. You can cash
						out your Imperium Index Investment only if you have the necessary
						quantity of $LEX to be whitelisted (which is equal to the set
						quantity of $LEX used for buying Imperium).
						<br />
						<br />
						Your $LEX can become productive assets from day one through staking
						& liquidity providing.
					</p>
					{/* <Modal
            title="How It Works"
            content={
              <div>
               
                <p className="pb-2">
                  <b>Step 1:</b> Purchase $LEX tokens from official addresses to
                  be whitelisted to invest in the Imperium index.
                </p>
                <p className="pb-2">
                  <b>Step 2:</b> Provide proof of purchase through your
                  transaction hash on the blockchain and a 1 minute KYC/AML
                  procedure.
                </p>
                <p className="pb-2">
                  <b>Step 3:</b> Choose and invest in the index of your choice.
                </p>
                <p className="pb-2">
                  <b>Step 4:</b> Receive & Store your NFT proof of ownership.
                </p>
                <p className="pb-2">
                  <b>Step 5:</b> Stake or even sell your $LEX.
                </p>
                <p className="pb-2">
                  <b>Step 6:</b> Make sure to whitelist your liquidation
                  transaction by owning the initial required amount of $LEX and
                  liquidate your investment at the moment of your choosing.
                </p>
                <p>
                  Ⓘ Regardless of the amount, you can only invest in Imperium
                  once per month. This timer resets on the first day of every
                  month. There will also be the option to get an extra Top-up
                  Ticket for Imperium. This will require a double amount of Lex
                  and vesting will be applied for one month (50% release in the
                  first month & 50% in the second one).
                </p>
              </div>
            }
          /> */}
				</div>
			</div>
			{/* <div className="md:mt-20 lg:my-auto">
        <div className="flex flex-col lg:flex-row mt-16 px-8 md:px-24">
          <div className="image-container w-1/2 flex justify-center self-center">
            <img src={phone} className="mx-auto" />
          </div>
          <div className=" lg:w-1/2 text-white pt-10 xl:pr-24">
            <h2 className="uppercase">Lex</h2>
            <h2 className="mb-4 uppercase">
              {" "}
              Imperios necesar pentru tranzactionarea lui IMPERIUM{" "}
            </h2>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="216"
              height="10"
              viewBox="0 0 216 10"
            >
              <line
                id="Line_18"
                data-name="Line 18"
                x2="216"
                transform="translate(0 5)"
                fill="none"
                stroke="#7fc5ff"
                strokeWidth="10"
              />
            </svg>
            <p className="mt-4 text-opacity">
              Fara acest token nu se poate tranzactiona IMPERIUM.
              <b> LEX</b> se regaseste si ca token independent fata de IMPERIUM;
              se poate tranzactiona si separat de exchange-uri. In momentul in
              care cumperi IMPERIUM, primesti automat <b>LEX</b>.
            </p>
            <Modal
              setScrollable={setScrollable}
              title="Mai multe despre LEX"
              content={
                <div>
                  <h3>De ce a fost creat LEX?</h3>
                  <p>
                    Scopul principal al proiectului IMPERIUM este acela de a
                    investi in primele monede din topul acestora in functie de
                    market cap. Cu toate acestea, pentru ca experienta de
                    investire sa fie mai placuta pentru utilizatori, creatorii
                    proiectului au adaugat la acest mecanism de tranzactionare,
                    o cryptomoneda numita LEX.
                  </p>
                  <br />
                  <p className="italic">
                    exemplu: IM10=T10+LEX T10= reprezinta o valoare raportata la
                    marketcap-ul total al cryptomonedelor de pe primele 10
                    pozitii din top in orice moment din timp; acesta este un
                    index matematic care nu se regaseste ca si moneda pe
                    exchange-uri si nu se poate tranzactiona independent.
                  </p>
                  <br />{" "}
                  <p>
                    <span className="font-bold"> LEX</span> se regaseste si ca
                    token independent fata de
                    <span className="font-bold"> IMPERIUM</span>; se poate
                    tranzactiona si separat pe exchange-uri. In momentul in care
                    cumperi <span className="font-bold">IMPERIUM</span>,
                    primesti automat <span className="font-bold">LEX</span> si
                    un index matematic notat{" "}
                    <span className="font-bold">Tn</span> (Tn nu se poate
                    tranzactiona si nu se regaseste pe platformele de
                    tranzactionare reprezentand o valoare raportata la
                    marketcap-ul total al primelor monede din top). Dupa
                    tranzactionare, in portofel detii{" "}
                    <span className="font-bold">IMPERIUM</span> compus{" "}
                    <span className="font-bold">LEX</span> si Tn. Poti sa
                    tranzactionezi separat{" "}
                    <span className="font-bold">LEX-ul</span> si iti ramane
                    blocata in portofel cantitatea de{" "}
                    <span className="font-bold">Tn</span> care se poate
                    tranzactiona ulterior doar dupa ce ai cumparat inapoi
                    cantitatea vanduta de <span className="font-bold">LEX</span>
                    .
                  </p>
                  <br />{" "}
                  <p>
                    Astfel, valoarea <span className="font-bold">IMPERIUM</span>{" "}
                    este influentata de puterea oferita de cryptomoneda{" "}
                    <span className="font-bold">LEX</span>. Chiar daca indexul{" "}
                    <span className="font-bold">Tn</span> este o valoare
                    matematica, prin fluctuatiile oferite de{" "}
                    <span className="font-bold">LEX</span>, valoarea{" "}
                    <span className="font-bold">IMPERIUM</span>
                    primeste o variatie suplimentara fata de indexul Tn. Cu
                    toate acestea, pentru a nu se dezechilibra balanta in
                    favoarea monedei <span className="font-bold">LEX</span>,
                    creatorii au avut grija ca aceasta sa nu reuseasca sa aiba o
                    putere atat de mare astfel incat sa domine graficul de
                    tranzactionare si sa puna indexul{" "}
                    <span className="font-bold">Tn</span> in inferioritate
                    atunci cand este in interiorul cryptomonedei IMn. Daca s-ar
                    ajunge in acest scenariu, utilizatorul nu ar mai
                    tranzactiona in primul rand topul primelor monede ci ar
                    tranzactiona o influenta majora a cryptomonedei{" "}
                    <span className="font-bold">LEX</span>.
                  </p>
                  <br />{" "}
                  <p>
                    <span className="font-bold">In schimb</span>, daca se
                    doreste doar tranzactionarea cryptomonedei{" "}
                    <span className="font-bold">LEX</span>, exista aceasta
                    posibilitate, ea regasindu-se independent in platformele de
                    tranzactionare. Prin proiectul{" "}
                    <span className="font-bold">IMPERIUM</span> si prin simbioza
                    intre indexul Tn si cryptomoneda{" "}
                    <span className="font-bold">LEX</span>, ia nastere un nou
                    ecosistem independent in cadrul acestei lumi a
                    cryptomonedelor, generand posibilitatea la nenumarate
                    combinatii de scenarii de tranzactionare.
                  </p>
                </div>
              }
            />
            <div className="mt-6">
              <Link to="/dashboard">
                <img src={button} className=" w-48" />
              </Link>
            </div>
          </div>
        </div>
      </div> */}
		</div>
	);
};
export default Slider3;
