import React from "react";
import { MetaTags } from "react-meta-tags";
import logonou from "../../assets/logonou.png";
import { Link } from "react-router-dom";

const TC = () => {
  return (
    <div>
      <MetaTags>
        <title>IMPERIUM - T&C</title>
        <meta name="description" content="THE CRYPTO INDEX" />
        <meta property="og:title" content="IMPERIUM" />
      </MetaTags>

      <div
        className="container-custom"
        style={{
          marginTop: 0,
          marginBottom: 0,
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        <div className="text-center uppercase">
          <Link to="/">
            <img src={logonou} className="logo_nou" />
          </Link>
        </div>
        {/* <h1 className="text-center uppercase">TERMENI SI CONDITII</h1> */}
        <div className="text-white">
          <p>
            <strong>Terms of Services</strong>
          </p>
          <p>
            WE ADVISE YOU TO READ CAREFULLY THESE TERMS OF SERVICES, THESE TERMS
            HAVE LEGAL EFECTS OF AN AGREEMENT, USING OUR PLATFORM YOU AGREE TO
            COMPLY WITH THESE TERMS OF SERVICES, AS WELL AS WITH ALL POLICIES,
            NOTICES AND INFORMATION REFERRED ON THE PLATFORM
          </p>
          <p>
            <strong>1. </strong>
            <strong>Our company</strong>
          </p>
          <p>Blockchain Build Solutions S.R.L.,</p>
          <p>
            Address: Rosu, Chiajna, Street Rezervelor, Nr. 66C, Floor 3, Ap. 12,
            Office nr. 1, County Ilfov, Romania.
          </p>
          <p>Reg.Nr: CUI 44925343, J23/555751/17.09.2021,</p>
          <p>Owners: Corobea Birceanu Andrei, Manda Liviu, Manda Vlad.</p>
          <p>
            <strong>GDPR</strong>
          </p>
          <p>
            General Data Protection Regulation i.e. Regulation (EU) 2016/679 of
            27 April 2016 on the protection of natural persons with regard to
            the processing of personal data and on the free movement of such
            data, and repealing Directive 95/46/EC (general Protection
            Regulation);
          </p>
          <p>
            <strong>General Facts </strong>
          </p>
          <p>
            During the term of this Agreement and after its termination, we do
            not provide any investments advice and will not be held accountable
            for any actions or losses that may arise during the use of our LEX
            and/or platform. This agreement is concluded for an indefinite
            period of time.
          </p>
          <p>
            <strong>Imperium Index – the Platform and the $LEX TOKEN</strong>
          </p>
          <p>
            Imperium is both a collection of indexes that are tracking the top
            cryptocurrencies worldwide and a tool for investing in them
            according to each asset’s market cap.
          </p>
          <p>
            It is a means of investing in the entire market, rather than any
            individual project, in order to mitigate risk and effort.
          </p>
          <p>
            One Imperium can be defined as the one billionth part of the index
            it represents, and will always be priced as such.
          </p>
          <p>
            When purchasing one IM10 token, you will always be paying a sum
            equal to the total market cap of your index, divided by one billion.
          </p>
          <p>
            <strong> </strong>
          </p>
          <p>
            <strong>
              $LEX is an independent token fueling the Imperium ecosystem.
            </strong>
          </p>
          <p>
            In order to be able to whitelist your investment in Imperium, you
            must own a set quantity of $LEX.
          </p>
          <p>This token can be traded separately on exchanges.</p>
          <p>
            After you’ve deposited your money and invested them in the indexes,
            by purchasing Imperium, you are issued a custom NFT that constitutes
            proof of ownership of the underlying assets and is redeemable for
            the value of your investment.
          </p>
          <p>
            These NFTs are transferable, inheritable, and vendible, and offer
            the holder (who has taken possession of it through the previous
            methods) the possibility to liquidate the investment behind it,
            which is in the Imperium Index.
          </p>
          <p>
            NFTs should be securely stored since they guarantee the liquidation
            of the investment made in Imperium.
          </p>
          <p>
            After investing in Imperium, and gaining the NFT Proof of Ownership,
            $LEX can be sold or kept for staking.
          </p>
          <p>
            Your Imperium Index Investment can be liquidated only after you have
            bought back the sold quantity of $LEX and deposited the NFT.
          </p>
          <p>
            Your $LEX tokens can become productive assets from day one through
            staking. Staking rewards will be 10% at minimum, but realistically,
            if you're an early investor and you stake from day one, you'll
            benefit from APY returns ranging from 32% to 80%.
          </p>
          <p>
            <strong> </strong>
          </p>
          <p>
            <strong>How Imperium (&amp; $LEX) work</strong>
          </p>
          <p>
            Step 1: Purchase $LEX tokens to be whitelisted to invest in the
            imperium index.
          </p>
          <p>Step 2: Choose and invest in the index of your choice.</p>
          <p>Step 3: Receive &amp; Store your NFT proof of ownership.</p>
          <p>Step 4: Stake or even sell your $LEX.</p>
          <p>
            Step 5: Make sure to whitelist your transaction by owning the
            initial required amount of $LEX and liquidate your investment at the
            moment of your choosing.
          </p>
          <p>
            <strong> </strong>
          </p>
          <p>
            <strong>Restrictions</strong>
          </p>
          <p>
            By accessing the platform, you confirm that you are an individual
            over 18 years of age, and you have full capacity to use and exercise
            the civil rights existing under the applicable laws of the
            jurisdiction. <strong> </strong>
          </p>
          <p>
            <strong>The Company can limit, restrict</strong>
            <strong>or deny</strong>
            the access to the Platform and to the Services to individuals that
            do not fit and in accordance with laws in certain countries or
            regions. <strong></strong>
          </p>
          <p>
            <strong>GOVERNING LAW</strong>
          </p>
          <p>
            This Agreement is governed by the laws of Romania, or according to
            the law applicable within the jurisdiction the Company is duly
            incorporated in.
          </p>
          <p>
            <strong>Privacy Policy</strong>
          </p>
          <p>
            <strong> </strong>
          </p>
          <p>
            Please be informed that we are going to protect all your personal
            data and to respect your privacy.
          </p>
          <p>
            This privacy policy, will provide you all the information on the
            processing of your personal data that we are going to collect from
            you by using of the website
          </p>
          <p>
            We will do our best to ensure the confidentiality and security of
            your personal data through the Platform, but we cannot respond by
            some of our partners that are also data controllers.
          </p>
          <p>
            <strong>Your personal data</strong>
            may be processed for different purposes. The personal data that we
            collect in the process of managing the Platform and offering our
            services to the users may be provided by the users of this Platform
            directly: e.g., name, surname, e-mail address, telephone number and
            any other data provided in the context of the interaction with the
            Platform.
          </p>
          <p>
            <strong>Categories of data</strong>: type of account, copy of an
            identification document (passport or identity card), a document
            revealing the bank account number (IBAN) that will be used for
            deposits or withdrawals (bank account statement or a print screen
            from the bank app revealing the name and the IBAN of the user).
          </p>
          <p>
            <strong>Categories of account data:</strong>
            username, type of account, photo, relevant information regarding
            your participation to the contest/promotion<strong></strong>
          </p>
          <p>
            Regarding the legal obligations of Anti Money Laundering and
            Terrorist Financing Regulations we will processed your Personal Data
            requested in the exercise of official authority accredited by the
            Company.
          </p>
          <p>
            If you consider that certain information about you is inaccurate,
            you may request rectification of such data to be rectify, block, or
            erase from your Personal Data by the Company. Although all
            reasonable efforts will be made to keep your information updated,
            you are kindly requested to inform us of any change referring to the
            Personal Data held by this Company.
          </p>
          <p>
            We inform you that the Company may share the Participants' data with
            others in order to provide the best services for you: with partners
            necessary to acquire, hold, or use the Token and/or providing the
            Services; when using interactive areas of the Website, like our blog
            or other online forums, certain information you choose to share may
            be displayed publicly, such as your username, actions you take, and
            any content you post; to Courts and Government authorities or bodies
            if requested to do so under a court order or legal process, or to
            establish or exercise the Company's legal rights or defend against
            legal claims.
          </p>
          <p>
            You may request the Company to access your information and Personal
            Data at any time.
          </p>
          <p>
            Any claims, questions, comments, and requests regarding this Privacy
            Policy should be addressed to the Company's email{" "}
            <a href="mailto:contact@imperium-lex.com">
              contact@imperium-lex.com
            </a>
            during regular business hours on any business day.
          </p>
          <p>
            This Privacy Policy will be updated from The Company regarding the
            changing legal, technical, and/or business development. The revised
            Privacy Policy will take effect immediately upon publication by the
            Company and we will replace this page with an updated version.
          </p>
          <p>
            Please be informed that it is your sole responsibility to check the
            "Privacy Policy" page from time to any time so as to be aware of any
            changes which may have occurred from time to time.
          </p>
          <p>
            <strong> </strong>
          </p>
          <p>
            <strong>Cookie Policy</strong>
          </p>
          <p>
            <strong> </strong>
          </p>
          <p>
            The information’s may be collected by cookies and other tracking
            methods. Cookies are small data files that are stored on your device
            when visiting a website, which enables the Company to collect
            information about your device identifiers, web browsers used to
            access the Services, pages or features viewed, time spent on pages,
            mobile app performance, and links clicked. Web beacons (or pixel
            tags) are electronic images that may be used to help deliver
            cookies, count website visits, understand usage and determine the
            effectiveness of email marketing campaigns.
          </p>
          <p>
            A cookie (also known as a “browser cookie”, “HTTP cookie” or simply
            “cookie”) is a small piece of data that a platform asks your browser
            to store on your computer, mobile terminal or other electronic
            devices on which a user browses the internet, to help the user
            navigate the platform efficiently, to perform certain functions and
            to allow the platform to “remember” the user’s actions or
            preferences over time.
          </p>
          <p>
            The information collected through cookies is not likely to lead to
            the identification of internet users, however, cookies may track
            information such as the IP address, geolocation data, operating
            system data, resolution of the device used, data regarding the
            visits on the Platform (including the number of visits, the time
            spent on the Platform, the manner in which the platform is accessed,
            the behavior on the Platform), the organic traffic (representing the
            traffic generated on our website).
          </p>
          <p>
            The Company may collect location data or use various means to
            determine the location in case of using a location-enabled device.
          </p><br/>
          <p><strong>Company data: </strong> <a href="https://rekvizitai.vz.lt/m/en/company/boskalma/" target="_blank" className="underline hover:text-gray-500">https://rekvizitai.vz.lt/m/en/company/boskalma/</a></p>
          <p>Company name: Boskalma, UAB</p>
          <p> Registration code: 306025757</p>
          <p>OSIM: SLR Algorithm</p>
          <p>Registration number name: EFRO202200000571135</p>
          <p>OSIM registration number: EFRO202200000570902</p>
          <p>OSIM logo registration number: EFRO202200000571082</p>
          {/* <p>Manager:	LIVIU MANDA </p>
          <p>Address:	Eišiškių Sodų 18-oji g. 11, LT-02194 Vilnius</p>
          <p>Mobile phone: <a href="tel:+40735102050" className="underline hover:text-gray-500">+40735102050</a></p>
          <p>Email address: <a href="mailto:contact@imperium-lex.com" className="underline hover:text-gray-500">contact@imperium-lex.com</a></p>
          <p>Website:	<a href="http://imperium-lex.com" target="_blank" className="underline hover:text-gray-500">http://imperium-lex.com</a> </p>
          <p>Facebook:	 <a href="https://www.facebook.com/IMPERIUMINDEX" target="_blank" className="underline hover:text-gray-500">https://www.facebook.com/IMPERIUMINDEX</a></p>
          <p>Telegram: <a href="https://t.me/ImperiumEN" target="_blank" className="underline hover:text-gray-500">https://t.me/ImperiumEN</a>	</p> */}
        </div>
      </div>
    </div>
  );
};

export default TC;
