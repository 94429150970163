import React from "react";
import in_dark from "../../assets/social/in_dark.png";
import twitter02_dark from "../../assets/social/twitter02_dark.png";
import website_icon from "../../assets/social/website.png";

const SocialMediaTeam = ({ linkedin, twitter, site }) => {
  return (
    <div className="flex items-center mb-2 sm:mb-4 gap-2">
      {linkedin ? (
        <a href={linkedin} target="_blank">
          <img src={in_dark} />
        </a>
      ) : (
        ""
      )}

      {twitter ? (
        <a href={twitter} target="_blank">
          <img src={twitter02_dark} />
        </a>
      ) : (
        ""
      )}

      {site ? (
        <a href={site} target="_blank">
          <img src={website_icon} />
        </a>
      ) : (
        ""
      )}
    </div>
  );
};

export default SocialMediaTeam;
