import React, { useContext, useState } from "react";
import { Redirect, useHistory } from "react-router";
import { Link } from "react-router-dom";
import { createUser } from "../../Api/Api";
import { UserContext } from "../../Providers/UserProvider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../assets/logonou.png";
import signUpBtn from "../../assets/signupbtn.png";
import { registerWithEmailAndPassword } from "../../Api/firebaseConfig";
import ReCAPTCHA from "react-google-recaptcha";

const Register = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const [user, setUser] = useContext(UserContext);
	const [recap, setRecap] = useState("");

	function onChangeRecap(value) {
		setRecap(value);
	}

	let history = useHistory();

	const handleRegister = async event => {
		event.preventDefault();

		if (password != confirmPassword) {
			toast.error("Your passwords must match!", {
				position: "bottom-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				draggable: true,
				progress: undefined
			});
			return;
		} else {
			if (recap === "") {
				toast.error("Please press the check button!", {
					position: "bottom-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					progress: undefined
				});
			} else {
				registerWithEmailAndPassword({ email, password });
			}
		}
	};

	if (user) {
		return <Redirect to="/dashboard" />;
	}

	return (
		<>
			<div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
				<div className="max-w-lg w-full space-y-8  p-10 rounded-2xl">
					<div>
						<Link to="/">
							<img className="mx-auto w-40" src={logo} alt="Workflow" />
						</Link>
						<h2 className="mt-6 text-center">Sign up</h2>
						<p className="mt-2 text-center text-sm text-gray-600">
							Or&nbsp;
							<Link
								to="/login"
								className=" text-lg text-blue-button hover:text-blue-b-h"
							>
								Sign in if you have an account
							</Link>
						</p>
					</div>
					<form className="mt-8 space-y-6" onSubmit={handleRegister}>
						<input type="hidden" name="remember" value="true" />
						<div className="rounded-md shadow-sm flex flex-col gap-2 text-white">
							<div>
								<label for="email-address" className="sr-only">
									Email address
								</label>
								<input
									id="email-address"
									name="email"
									type="email"
									autocomplete="email"
									required
									value={email}
									onChange={e => setEmail(e.target.value)}
									className=" px-2 mt-1  focus:border-gray-400 block w-full bg-gray-900 py-2 shadow-sm sm:text-base border-2 border-gray-800 rounded-md"
									placeholder="Email address"
								/>
							</div>
							<div>
								<label for="password" className="sr-only">
									Repeat Password
								</label>
								<input
									id="password"
									name="password"
									type="password"
									autocomplete="current-password"
									required
									value={password}
									onChange={e => setPassword(e.target.value)}
									className=" px-2 mt-1  focus:border-gray-400 block w-full bg-gray-900 py-2 shadow-sm sm:text-base border-2 border-gray-800 rounded-md"
									placeholder="Password"
								/>
							</div>
							<div>
								<label for="confirm-password" className="sr-only">
									Confirm Password
								</label>
								<input
									id="confirm-password"
									name="confirm-password"
									type="password"
									autocomplete="current-password"
									required
									value={confirmPassword}
									onChange={e => setConfirmPassword(e.target.value)}
									className=" px-2 mt-1  focus:border-gray-400 block w-full bg-gray-900 py-2 shadow-sm sm:text-base border-2 border-gray-800 rounded-md"
									placeholder="Confirm Password"
								/>
							</div>
						</div>
						<ReCAPTCHA
							sitekey="6LdHoEAgAAAAAP_pqNNDVyx6Bx1m8QsDGaJGuer9"
							onChange={onChangeRecap}
						/>
						<div className="flex justify-center">
							<button
								type="submit"
								className="block whitespace-nowrap inline-flex items-center justify-center px-3 xl:px-10 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-button hover:bg-blue-b-h"
							>
								SIGN UP
							</button>
						</div>
					</form>
					<hr />
					<div className="flex flex-row justify-center items-center space-x-3">
						<Link
							to="/"
							className="block whitespace-nowrap inline-flex items-center justify-center px-3 xl:px-10 py-2  rounded-md shadow-sm text-base font-medium text-white "
						>
							GO HOME
						</Link>
						{/* <div
              className="w-11 h-11 items-center justify-center inline-flex rounded-2xl font-bold text-lg   bg-white-700 border-2 hover:shadow-lg cursor-pointer transition ease-in duration-300"
              // onClick={signInWithGoogle}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                  <path
                    fill="#4285F4"
                    d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"
                  />
                  <path
                    fill="#34A853"
                    d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"
                  />
                  <path
                    fill="#FBBC05"
                    d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"
                  />
                  <path
                    fill="#EA4335"
                    d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"
                  />
                </g>
              </svg>
            </div> */}
					</div>
				</div>
			</div>
			<ToastContainer />
		</>
	);
};

export default Register;
