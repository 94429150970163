import React, { useState, useEffect } from "react";
import { getMarketCap } from "../../Api/Api";

const Banner = () => {
	const [marketCap, setMarketCap] = useState(0);

	useEffect(() => {
		getMarketCap().then(response => {
			setMarketCap(parseFloat(response.value / 1000000000).toFixed(2));
		});
	});
	return (
		<>
			<div className="container-custom relative grid lg:grid-cols-2 sm:gap-10 items-center justify-between ">
				<div className=" flex flex-col justify-center  pt-10">
					<h5 className="text-blue-button font-bold text-2xl xl:text-3xl 2xl:text-4xl  font-primary uppercase font-lato-css mb-5">
						Imperium Reference Index - IMΣ
					</h5>
					<p className="font-bold text-primary text-white font-primary text-base sm:text-xl font-lato-css">
						It is an index that has the role of assessing the state of the
						crypto market. It's tied to the total market capitalisation, being
						calculated as 1:1,000,000,000 of this amount.
					</p>
				</div>
				{marketCap ? (
					<div className="text-center flex justify-center mt-10 mb-10 sm:mb-0">
						<span className="text-white font-bold  text-xl lg:text-2xl font-primary font-lato-css">
							IMΣ : ${marketCap}
						</span>
					</div>
				) : (
					""
				)}
			</div>
		</>
	);
};

export default Banner;
