import React from "react";

import telegram from "../../assets/social/telegram-icon.png";

const Cta = ({ text, href, className, icon, target })  => {
  return (
    <a href={href} target={target} className={`button-main ${className}`}>
      {text}{" "}
      {icon == "telegram" ? (
        <img src={telegram} style={{ width: "25px", paddingLeft: "5px" }} />
      ) : (
        ""
      )}
    </a>
  );
};

export default Cta;
