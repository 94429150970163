import React, { useEffect, useState } from "react";
import TOKE from "../../../assets/TOKE PIE 3.0.png";
import { getTopCoinsData } from "../../../Api/Api";
import { Cta } from "../../../Components";
import Chart from "react-google-charts";
import image_token from "../../../assets/tokenomics_alpha-min.png";
const Slider8 = ({ anchor, textlink }) => {
	const [tabText, setTabText] = useState("Tab1 text");
	const [coinsData, setCoinsData] = useState();
	useEffect(() => {
		getTopCoinsData().then(data => setCoinsData(data));
	}, []);

	return (
		<div
			className="container-custom flex flex-col  align-items-center"
			id={`${anchor}`}
		>
			<div className="md:mt-20 xl:my-auto">
				<div className="flex flex-col text-white mt-8 ">
					<h2 className="font-bolder text-center uppercase mb-4">Tokenomics</h2>
					<h3 className="font-bolder text-center uppercase mb-4">
						Token allocation
					</h3>
					<p className="font-bolder text-center text-xs sm:text-base uppercase mb-4">
						LEX IS SET TO BE A MULTICHAIN TOKEN. The LEX token will become your
						key to using Imperium at its full potential, for both investing in
						The Crypto Index, BUT ALSO FOR STAKING, LIQUIDITY PROVIDING AND
						COLLECTING REWARDS FROM MULTIPLE SOURCES.
					</p>
					<div>
						<img src={image_token} />
					</div>
					<div className="rotate-chart flex justify-center  sm:-my-40 hidden">
						<Chart
							width="100%"
							height={window.innerWidth < 640 ? "300px" : "1000px"}
							chartType="PieChart"
							loader={<div>Loading Pie Chart</div>}
							data={[
								["TOKEN", "A"],
								["Private State", 5.4],
								["ICO - IEO", 21.88],
								["IDO", 2.72],
								["Team", 15],
								["Advisors", 4],
								["Marketing", 18],
								["Project Treasury", 12],
								["LP & Staking Reward", 21]
							]}
							options={{
								is3D: true,
								backgroundColor: {
									fill: "transparent"
								},
								legend:
									window.innerWidth < 640
										? {
												position: "right",
												textStyle: { color: "white" },
												alignment: "end"
										  }
										: { position: "labeled", textStyle: { color: "white" } },
								pieSliceText: "percentage",
								pieSliceTextStyle: {
									bold: true,
									fontSize: window.innerWidth < 640 ? 5 : 14
								}
							}}
						/>
					</div>
					<div className="hidden text-white flex items-center flex-col overflow-x-auto">
						<table className="tabel-vesting table-fixed text-xs sm:text-base overflow-x-auto block mx-auto py-10">
							<thead>
								<tr className="">
									<th className="border w-32"> </th>
									<th className="border  w-32"> Private Sale</th>
									<th className="border  w-32">ICO - IEO</th>
									<th className="border  w-32">IDO</th>
									<th className="border w-32">Team</th>
									<th className="border  w-32">Advisors</th>
									<th className="border  w-32">Marketing</th>
									<th className="border  w-32 px-2">Project Treasury</th>
									<th className="border  w-32 px-2">LP & Staking Rewards</th>
									<th className="border  w-32">Total</th>
								</tr>
							</thead>
							<tbody>
								<tr className="">
									<td className="border px-2">
										<span className="float-right">Token Allocation</span>
									</td>
									<td className="border px-2">
										LEX <span className="float-right">1,713,350</span>
									</td>
									<td className="border px-2">
										LEX <span className="float-right">6,945,113</span>
									</td>
									<td className="border px-2">
										LEX <span className="float-right">862,613</span>
									</td>
									<td className="border px-2">
										LEX <span className="float-right">4,760,538</span>
									</td>
									<td className="border px-2">
										LEX <span className="float-right">1,269,477</span>
									</td>
									<td className="border px-2">
										LEX <span className="float-right">5,712,645</span>
									</td>
									<td className="border px-2">
										LEX <span className="float-right">3,808,430</span>
									</td>
									<td className="border px-2">
										LEX <span className="float-right">6,664,753</span>
									</td>
									<td className="border px-2">
										LEX <span className="float-right">31,736,917</span>
									</td>
								</tr>
								<tr className="border">
									<td className="border px-2">
										<span className="float-right">% of Total Supply</span>
									</td>
									<td className="border px-2">
										<span className="float-right">5.40%</span>
									</td>
									<td className="border px-2">
										<span className="float-right">21.88%</span>
									</td>
									<td className="border px-2">
										<span className="float-right">2.72%</span>
									</td>
									<td className="border px-2">
										<span className="float-right">15.00%</span>
									</td>
									<td className="border px-2">
										<span className="float-right">4.00%</span>
									</td>
									<td className="border px-2">
										<span className="float-right">18.00%</span>
									</td>
									<td className="border px-2">
										<span className="float-right">12.00%</span>
									</td>
									<td className="border px-2">
										<span className="float-right">21.00%</span>
									</td>
									<td className="border px-2">
										<span className="float-right">100.00%</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					{/* <img src={TOKE} className="w-1/2 m-auto"/> */}

					<ul className="text-sm sm:text-lg pb-10 hidden">
						<li>
							<b>🔹 Limited supply:</b> 31.736.917 LEX.
						</li>
						<li>
							<b>🔹 Initial circulating supply:</b> 2.555.290 LEX.
						</li>
						<li>
							<b>🔹 LEX Staking </b>
						</li>
						<li>
							<b>🔹 Liquidity Providing</b>
						</li>
					</ul>
					<div>
						<Cta
							className="hidden"
							text={textlink}
							href={
								"https://medium.com/@imperiumindex/everything-you-need-to-know-about-lex-tokenomics-7b63bf183771 "
							}
							target="_BLANK"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Slider8;
