import React from 'react';
import litepaperpdf from '../../assets/Litepaper 2.0.pdf';
import whitepaperpdf2 from '../../assets/IMPERIUM-Whitepaper_compressed.pdf';
import { Footer } from '../../Components';
import Header from '../../Components/Header/Header';
import WhitePaper from '../Sliders/WhitePaper/WhitePaper';
const WhitePaperPdf = () => {
  return (
    <>
      <iframe className="w-full h-screen" src={whitepaperpdf2}></iframe>
    </>
  );
};

export default WhitePaperPdf;
