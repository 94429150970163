import React, { useState, useContext } from "react";

import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { Link } from "react-router-dom";
import { UserContext } from "../../Providers/UserProvider";
import { handleLogout } from "../../Utils";
import { toast } from "react-toastify";
import { storage } from "../../Api/firebaseConfig";
import { updateUserProfileImage } from "../../Api/Api";
import { auth } from "../../Api";
import { useEffect } from "react";

const AvatarProfile = () => {
	const [user, setUser] = useContext(UserContext);
	const [file, setFile] = useState("");

	useEffect(() => {
		if (user) {
			setFile(user.user.profilePic || ""); //TODO
		}
	}, []);

	const onChangeHandlerFile = e => {
		e.preventDefault();
		const file = e.target.files[0];
		if (!file) return null;
		const storageRef = ref(storage, `profilepics/${user.user.uid}`);
		const uploadTask = uploadBytesResumable(storageRef, file);
		uploadTask.on(
			"state_changed",
			snapshot => {
				const progress = Math.round(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);
			},
			error => {
				alert(error);
			},
			() => {
				getDownloadURL(storageRef).then(downloadURL => {
					auth
						.auth()
						.currentUser.getIdToken(/* forceRefresh */ true)
						.then(async function (idToken) {
							updateUserProfileImage(idToken, { profilePic: downloadURL }).then(
								res => {
									console.log(res);
									setFile(downloadURL);
									setUser(prevState => ({
										...prevState,
										user: {
											...prevState.user,
											["profilePic"]: downloadURL
										}
									}));
								}
							);
						});
					toast.success("Image loaded!", {
						position: "bottom-center",
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						draggable: true,
						progress: undefined
					});
				});
			}
		);
	};

	return (
		<div className="relative z-10">
			<div className="md:absolute  w-full -top-32 flex flex-col gap-5">
				<div className="avatar bg-c-profile rounded-xl flex flex-col items-center py-8 px-4">
					<div className="text-white">
						<label className="input-camera relative">
							<input
								type="file"
								id="avatar"
								className="sr-only"
								onChange={onChangeHandlerFile}
								accept="image/png, image/jpeg, image/webp"
								name="upload_photo"
							/>
							<div className="text-center mx-auto w-48 h-48 hover-camera bg-blue-menu rounded-full">
								{file ? (
									<img
										for="avatar"
										className="cursor-pointer rounded-full w-48 h-48"
										src={file}
									/>
								) : (
									""
								)}

								<span className="hover-camera-show hidden absolute cursor-pointer bg-blue-menu  bottom-0 rounded-full bg-secondary p-2 opacity-80  text-center mx-auto  w-48 h-48  items-center justify-center">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="40"
										height="40"
										fill="currentColor"
										className="bi bi-camera-fill"
										viewBox="0 0 16 16"
									>
										<path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
										<path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
									</svg>
								</span>
							</div>
						</label>

						{user.user && user.user.email ? (
							<>
								<h5 className="text-xl font-bold py-2 break-all	text-center">
									{user.user.email}
								</h5>
							</>
						) : (
							""
						)}
					</div>
				</div>
				{window.location.pathname === "/profile/profile-edit" ? (
					<Link
						to="/dashboard"
						className="edit bg-c-profile hover:bg-black-b-hover duration-300 w-full inline-block flex justify-center py-3 font-bold text-white rounded-lg"
					>
						Profile
					</Link>
				) : (
					<>
						<Link
							to="/profile/profile-edit"
							className="edit bg-c-profile hover:bg-black-b-hover duration-300 w-full inline-block flex justify-center py-3 font-bold text-white rounded-lg"
						>
							Edit Profile
						</Link>
						<div
							onClick={handleLogout}
							className="edit bg-c-profile cursor-pointer hover:bg-black-b-hover duration-300 w-full inline-block flex justify-center py-3 font-bold text-white rounded-lg"
						>
							Log out
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default AvatarProfile;
