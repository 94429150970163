import "./App.css";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect
} from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import LoadingScreen from "./Components/LoadingScreen/LoadingScreen";
import Legal from "./Pages/Legal/Legal";
import { ToastContainer } from "react-toastify";
import "./App.css";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import {
	Imperium,
	Lex,
	Mvp,
	HowItWorks,
	Dashboard,
	Register,
	Login,
	MainPage,
	TC,
	Profile,
	ProfileLex,
	ProfileImperium,
	ProfileNFT,
	ProfileEdit,
	TokenSale,
	PrivateSale,
	PublicSaleI,
	PublicSaleII,
	ForgotPassword,
	BlogAndSocial,
	Blog2,
	PaginaArticol,
	LitePaper
} from "./Pages";
import { LoadingContext, UserContext } from "./Providers";
import WhitePaperPdf from "./Pages/WhitePaperPdf/WhitePaperPdf";
import IM10 from "./Pages/Indices/IM10";
import IM20 from "./Pages/Indices/IM20";
import IM50 from "./Pages/Indices/IM50";
import IM10alt from "./Pages/Indices/IM10alt";
import IM20alt from "./Pages/Indices/IM20alt";
import IM50alt from "./Pages/Indices/IM50alt";
import DeFiIndex from "./Pages/Indices/DeFiIndex";
import ReferenceIndex from "./Pages/Indices/ReferenceIndex";

const TRACKING_ID = "UA-226395460-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const tagManagerArgs = {
	gtmId: "GTM-PRNQG83"
};

TagManager.initialize(tagManagerArgs);

function App() {
	const [user, setUser] = useContext(UserContext);
	const [isAuth, setIsAuth] = useState(true);
	const [loading] = useContext(LoadingContext);

	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
		if (user) {
			setIsAuth(true);
		} else {
			setIsAuth(false);
		}
	});
	useEffect(() => {
		const test = window.location.hash;
		setTimeout(() => {
			if (test && test.includes("#")) {
				console.log(test);
				const id = test.replace("#", "");
				const element = document.getElementById(id);

				if (element) element.scrollIntoView({ behavior: "smooth" });
			}
		}, [600]);
	});
	return (
		<>
			<Router>
				<Switch>
					{loading ? (
						<LoadingScreen />
					) : (
						<>
							<Route exact path="/">
								<MainPage />
								<ToastContainer />
							</Route>

							<Route exact path="/how-it-works">
								<HowItWorks />
								<ToastContainer />
							</Route>

							<Route exact path="/imperium">
								<Imperium />
								<ToastContainer />
							</Route>

							<Route exact path="/lex">
								<Lex />
								<ToastContainer />
							</Route>

							<Route exact path="/mvp">
								<Mvp />
								<ToastContainer />
							</Route>

							<Route exact path="/blog-social">
								<Blog2 />
								<ToastContainer />
							</Route>
							<Route exact path="/litepaper">
								<LitePaper />
								<ToastContainer />
							</Route>
							<Route exact path="/whitepaper">
								<WhitePaperPdf />
								<ToastContainer />
							</Route>

							<Route exact path="/blog/:slug">
								<PaginaArticol />
								<ToastContainer />
							</Route>
							<Route exact path="/legal/:slug">
								<Legal />
								<ToastContainer />
							</Route>
							<Route exact path="/tc">
								<TC />
							</Route>

							<Route
								exact
								path="/dashboard"
								render={() =>
									!isAuth ? (
										<Redirect to="/login" />
									) : (
										<>
											{" "}
											<ProfileLex />
											<ToastContainer />
										</>
									)
								}
							/>
							<Route
								exact
								path="/profile/lex"
								render={() =>
									!isAuth ? (
										<Redirect to="/login" />
									) : (
										<>
											{" "}
											<ProfileLex />
											<ToastContainer />{" "}
										</>
									)
								}
							/>

							<Route
								exact
								path="/profile/imperium"
								render={() =>
									!isAuth ? (
										<Redirect to="/login" />
									) : (
										<>
											<ProfileImperium />
											<ToastContainer />{" "}
										</>
									)
								}
							/>

							<Route
								exact
								path="/profile/nft"
								render={() =>
									!isAuth ? (
										<Redirect to="/login" />
									) : (
										<>
											{" "}
											<ProfileNFT />
											<ToastContainer />
										</>
									)
								}
							/>

							<Route
								exact
								path="/profile/profile-edit"
								render={() =>
									!isAuth ? (
										<Redirect to="/login" />
									) : (
										<>
											<ProfileEdit />
											<ToastContainer />
										</>
									)
								}
							/>

							{/* <Route
                exact
                path="/payment"
                render={() => (!isAuth ? <Redirect to="/login" /> : <Stripe />)}
              /> */}
							{/* <Route
                exact
                path="/dashboard"
                render={() =>
                  !isAuth ? <Redirect to="/login" /> : <Dashboard />
                }
              /> */}
							<Route
								exact
								path="/login"
								render={() =>
									isAuth ? <Redirect to="/dashboard" /> : <Login />
								}
							/>
							<Route
								exact
								path="/forgot-password"
								render={() =>
									isAuth ? (
										<Redirect to="/dashboard" />
									) : (
										<>
											<ForgotPassword />
											<ToastContainer />
										</>
									)
								}
							/>
							<Route
								exact
								path="/register"
								render={() =>
									isAuth ? <Redirect to="/dashboard" /> : <Register />
								}
							/>

							<Route exact path="/IM10">
								<IM10 />
								<ToastContainer />
							</Route>

							<Route exact path="/IM20">
								<IM20 />
								<ToastContainer />
							</Route>

							<Route exact path="/IM50">
								<IM50 />
								<ToastContainer />
							</Route>

							<Route exact path="/IM10alt">
								<IM10alt />
								<ToastContainer />
							</Route>
							<Route exact path="/IM20alt">
								<IM20alt />
								<ToastContainer />
							</Route>
							<Route exact path="/IM50alt">
								<IM50alt />
								<ToastContainer />
							</Route>
							<Route exact path="/DeFi-Index">
								<DeFiIndex />
								<ToastContainer />
							</Route>
							<Route exact path="/Reference-Index">
								<ReferenceIndex />
								<ToastContainer />
							</Route>
						</>
					)}
				</Switch>
			</Router>
		</>
	);
}

export default App;
