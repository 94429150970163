import React from "react";

const Line = ({ className }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="99"
			height="6"
			className={className}
			viewBox="0 0 99 6"
		>
			<line
				id="Line_18"
				data-name="Line 18"
				x2="216"
				transform="translate(0 5)"
				fill="none"
				stroke="#00d7fb"
				strokeWidth="10"
			></line>
		</svg>
	);
};

export default Line;
