import React from "react";

const Overview = ({ items }) => {
  return (
    <div className=" pt-5 sm:pt-10 grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 sm:gap-5 sm:-ml-5">
      {items.map((item, index) => (
        <div className="2xl:px-5">
          <div className="bg-c-profile rounded-xl">
            <div className="flex justify-between text-gray-500 px-5 py-2">
              <h5>{item.number}</h5> <p>...</p>
            </div>
            <div className="h-72 bg-gray-800 flex items-center justify-center rounded-b-xl">
              <h3>{item.name}</h3>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Overview;
