// import './MainPage.css';
import React from 'react';
import { BrowserRouter as Router, Link, useHistory } from 'react-router-dom';

import '../../style.css';

import whitepaper from '../../../assets/whitepaper.png';
import Line from '../../../Components/Line/Line';
import litepaperpdf from '../../../assets/Litepaper Imperium 2.0.pdf';
import { Cta } from '../../../Components';

const WhitePaper = ({ anchor, title, subtitle, paragraph, link }) => {
  return (
    <div className="container-custom slider-4" id={`${anchor}`}>
      <div className="flex flex-col lg:flex-row gap-5">
        <div className="flex justify-center self-center order-1 md:order-none">
          <img src={whitepaper} loading="lazy" />
        </div>
        <div className="zona-text ">
          <div>
            <h2 className="uppercase">{title}</h2>
            <h3 className="">{subtitle}</h3>
            <Line className={'my-4'} />
            {paragraph}
            <Link to="/whitepaper" target="_BLANK">
              <Cta
                text="Read more"
                // href={litepaperpdf}
                target="_BLANK"
                className="text-lg"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WhitePaper;
