import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../Components/Header/Header";
import { Footer } from "../../Components";
import "../../assets/csscustom/csscustom.css";

const PaginaArticol = props => {
	const [post, setPost] = useState();
	const { slug } = useParams();
	useEffect(() => {
		fetch("https://cms.imperium-lex.com/wp-json/wp/v2/posts?slug=" + slug)
			.then(response => response.json())
			.then(posts2 => {
				console.log(posts2);
				setPost(posts2);
			});
	}, [props]);

	return (
		<>
			<Header />
			<div className="pt-24"></div>

			<div className="container-custom articol">
				{!post ? (
					""
				) : (
					<>
						<div className="mx-auto mb-10">
							<div
								className="text-white text-center "
								dangerouslySetInnerHTML={{
									__html:
										'<h1 className="text-3xl md:text-5xl font-bold">' +
										post[0].title.rendered +
										"</h1>"
								}}
							></div>
						</div>
						<div
							className="text-white"
							dangerouslySetInnerHTML={{ __html: post[0].content.rendered }}
						/>
					</>
				)}
			</div>
			<Footer />
		</>
	);
};

export default PaginaArticol;
